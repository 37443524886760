<template>
    <div class="EnterprisePoster" v-if="Posterbox">
        <div class="swiper" >
            <div class="Posterbox" >
                <div class="Poster_nav">
                    <div class="Poster_default">
                        <div class="imgbox" @click="chooseItem('moren',)">
                            <img style="display: block;" src="../../assets/image/erweimaxy.png" alt="">
                            <div class="text">
                                <p>默认</p>
                            </div>                            
                        </div>
                    </div>
                    <div class="Poster_custom" @scroll="scrollEvent">
                        <div class="Poster_custom_box" :style="{width:imgs?imgs.length*2+'rem':'0'}" >
                            <div class="imgbox" v-for="(item , index) in imgs" :key="index" @click="chooseItem(item)">
                                <div style="width: 100%;height: 100%;position: relative;">
                                    <img style="display: block;" :src="item.Imgsrc" alt="">
                                    <div class="text">
                                        <p>{{item.Name}}</p>
                                    </div> 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
    computed: {
        ...mapGetters({
            LoginUserInfo: "LoginUserInfo",
            Companylink:'Companylink',
        }),
    },
    data() {
        return {
            imgs:[],
            EnterprisePosters:{
                id:'',
                page:'1',
                pagesize:'20',
                datasort:'',
                search:'',
            },
            Posterbox:false,
        }
    },
    methods: {
        GetPoster(){
            let _this = this
            _this.$ajax.getAjax("api/EnterprisePoster/EnterprisePosters",_this.EnterprisePosters)
            .then(res=>{
                if(res.data.meserror == 'ok'){
                    _this.imgs = res.data.mesdata.data
                    localStorage.setItem('imgsUrl', JSON.stringify(_this.imgs) );
                }
            });
        },
        chooseItem(items){
            let _this = this
            if(items == 'moren'){
                let item={
                    qrcodeMax:'2.820',
                    qrcodeTop:'4.000',
                    qrcodeLeft:'2.400',
                    Imgsrc:'',
                }
                _this.$emit('EnterprisePoster',item)
            }else{
                _this.$emit('EnterprisePoster',items)
            }   

           
        },

        scrollEvent(e){
            // console.log(e.currentTarget.scrollWidth)
            // console.log(e.currentTarget.scrollLeft + e.currentTarget.clientWidth)
            if(e.currentTarget.scrollLeft + e.currentTarget.clientWidth >= e.currentTarget.scrollWidth){
              
            }else{
               
            }
        }
    },
    created() {
        let _this = this
        if(_this.LoginUserInfo){
            _this.EnterprisePosters.id  = JSON.parse(_this.LoginUserInfo).EnterpriseId
        }        
        _this.GetPoster()
    }
}
</script>

<style lang="scss" >
    .EnterprisePoster{
        .swiper{
            
            .Posterbox{
                width: 100%;
                height: 2.5rem;
                position: fixed;
                bottom: 0;
                z-index: 100;
                .Poster_nav{
                    width: 100%;
                    height: 100%;
                    display: flex;
                    .imgbox{
                        width: 1.9rem;
                        height: 100%;
                        margin: 0 .05rem;
                        float: left;
                        overflow: auto;
                        position: relative;
                        .text{
                            position: sticky;
                            bottom: 0;
                            text-align: center;
                            width: 1.9rem;
                            font-size: .28rem;
                            background: rgba(0,0,0,.5 );
                            padding: 0 .1rem;
                            p{
                                padding: .02rem 0;
                                color: #fff;
                                white-space: nowrap;
                                overflow: hidden;
                            }
                        }
                    }                    
                    .Poster_default{
                        width: 2rem;
                        height: 100%;
                    }
                    .Poster_custom{
                        flex: 1;
                        overflow: auto;
                        .Poster_custom_box{
                            width: auto;
                            height: 100%;
                        }
                    }
                }
            }
        }


        @keyframes Posterboxin {
            0%{
                opacity: 0;
            };
            50%{
                opacity: .5;
            };
            100%{
                opacity: 1;
            }
        }
        @keyframes Posterboxout {
            0%{
                opacity: 1;
            };
            50%{
                opacity: .5;
            };
            100%{
                opacity: 0;
            }            
        }
    }

</style>