<template>
  <div class="Navigationbar">
    <div class="navigation-G" v-if="LoginUserInfoR.IsAdmin">
      <div class="tite">管理员</div>
      <div class="navigation-G-box">
        <div  class="navigationnav" @click="routejump('PeopleList')">
          <div class="navigationnav-top">
            <div class="iconbox">
              <svg class="icon" aria-hidden="true">
                <use xlink:href="#icon-yewuyuanshuliang2-"></use>
              </svg>
            </div>
          </div>
          <div class="navigationnav-bottom">查看业务员</div>
        </div>
        <div class="navigationnav"  @click="routejump('StudentList')">
          <div class="navigationnav-top">
            <div class="iconbox">
              <svg class="icon" aria-hidden="true">
                <use xlink:href="#icon-xueyuan"></use>
              </svg>
            </div>
          </div>
          <div class="navigationnav-bottom">查看学员</div>
        </div>
        <div class="navigationnav" @click="linkCreate('people')">
          <div class="navigationnav-top">
            <div class="navigationnav-top">
              <div class="iconbox">
                <svg class="icon" aria-hidden="true">
                  <use xlink:href="#icon-a-fuzhilianjielianjiefenxiang"></use>
                </svg>
              </div>
            </div>
          </div>
          <div class="navigationnav-bottom">生成公益大使入口</div>
        </div>
        <div  class="navigationnav" @click="routejump('Materiallibrary')">
          <div class="navigationnav-top">
            <div class="iconbox">
              <svg class="icon" aria-hidden="true">
                <use xlink:href="#icon-sucaiku"></use>
              </svg>
            </div>
          </div>
          <div class="navigationnav-bottom">素材库</div>
        </div> 
      </div> 
    </div>
    <div class="navigation-Y">
      <div class="tite">业务员</div>
      <div class="navigation-G-box">
        <div class="navigationnav" @click="routejump('StudentList')">
          <div class="navigationnav-top">
            <div class="navigationnav-top">
              <div class="iconbox">
                <svg class="icon" aria-hidden="true">
                  <use xlink:href="#icon-xueyuan"></use>
                </svg>
              </div>
            </div>
          </div>
          <div class="navigationnav-bottom">查看学员数</div>
        </div>
        <div class="navigationnav" @click="linkCreate('student')">
          <div class="navigationnav-top">
            <div class="navigationnav-top">
              <div class="iconbox">
                <svg class="icon" aria-hidden="true">
                  <use xlink:href="#icon-a-fuzhilianjielianjiefenxiang"></use>
                </svg>
              </div>
            </div>
          </div>
          <div class="navigationnav-bottom">生成课程申请码</div> 
        </div>
        <!-- <div  class="navigationnav" @click="routejump('ceshi2')">
          <div class="navigationnav-top">
            <div class="iconbox">
              <svg class="icon" aria-hidden="true">
                <use xlink:href="#icon-sucaiku"></use>
              </svg>
            </div>
          </div>
          <div class="navigationnav-bottom">海报选择</div>
        </div>   -->
      </div>
    </div>
 
    <urlpopupcomcopy :popupdata="popupdata" ref="reference"></urlpopupcomcopy>
    <div @click="newyemina" v-if="openids=='okSrrju2loJrwUsVheSuKS_lkYd8'" style="color:#fff; width:3rem;height:.6rem;background:orange;text-align:center; border-radius: .1rem ; margin:0 auto ; line-height: .6rem;"> 
        跳转测试
    </div>
  </div>
  
</template>
<script>
import { Dialog } from "@nutui/nutui";
import datanullsource from "@/components/datanullcommon/datanullsource";
import urlpopupcomcopy from "@/components/Popupcom/urlpopupcomcopy";
import { mapGetters } from "vuex";
export default {
  // props:['route'],
  components: { urlpopupcomcopy, datanullsource },
  data: function () {
    return {
      DataNullDes: {},
      popupdata: {},
      LoginUserInfoR:'',
    };
  },
  computed: {
    ...mapGetters({
      Companylink:'Companylink',
      wxinfo: "wxinfo",
      token: "token",
      LoginUserInfo: "LoginUserInfo",
      urlenterpriseid: "urlenterpriseid",
      urlpeopleid: "urlpeopleid",
    }),
  },
  methods: {
    routejump: function (address) {
      //页面路由
      let _this = this;
      _this.$router.push({
        name: address,
      });
    },
    linkCreate(val) {
      let _this = this;
      if(val=='people'){
        _this.DataNullDes = datanullsource.getData(val);
        _this.popupdata = _this.DataNullDes.popupdata;
        _this.popupdata.show = true;        
      }else if(val=='student'){
        Dialog({
            title: "提示",
            content: "是否生成指定连锁海报",
            okText:"去生成",
            cancelText:"生成代表海报",
            onOk() {
              _this.$router.push({
                name: 'Enterprisesearch',
                params: { isEnterprisesearch : true}
              });              
            },
            onCancel(){
              
              _this.DataNullDes = datanullsource.getData(val);
              _this.popupdata = _this.DataNullDes.popupdata;
              _this.popupdata.show = true; 
              _this.$refs.reference.QRcodeing = '1'
            }
        });        
      
      }
    },
    showModals() {
      let _this = this;
      (_this.editBtnShows = !_this.editBtnShows),
      (_this.zhezhao = !_this.zhezhao);
    },    
    newyemina(){
      let _this = this ;
      _this.$router.push({
          name: 'ceshi',
      });        
    }
  },
  created() {
    let _this = this;
    if(_this.Companylink){
      let Companylinks = JSON.parse(_this.Companylink)
      _this.DataNullDes = datanullsource.getData(Companylinks.val);
      _this.popupdata = _this.DataNullDes.popupdata;
    }
    if(localStorage.getItem('ispopupdata')){
      setTimeout(function (){
        _this.popupdata.show = true; 
        _this.$refs.reference.QRcodeing = '2'  
      }, 100);
    }
    if(_this.LoginUserInfo){
      _this.LoginUserInfoR = JSON.parse(_this.LoginUserInfo) ;
    }
    if(_this.wxinfo){
      _this.openids = JSON.parse(_this.wxinfo).openid
    }

  },
  watch:{

  }

};



</script>


<style lang="scss" >
.Navigationbar {
  width: 100%;
  height: 100%;
  .navigation-G,
  .navigation-Y {
    margin: 0.2rem 0.2rem;
    background: #fff ;
    border-radius: 0.1rem;
    
  }
  .navigationnav {
    width: calc(100% / 4);
    display: inline-block;
    margin: 0.2rem 0;
    vertical-align: top;
    padding: 0.2rem 0;
    
  }
  .navigationnav-top {
    width: 0.8rem;
    height: 0.8rem;
    // background: chartreuse;
    margin: 0 auto;
    border-radius: 0.1rem;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .navigationnav-bottom {
    text-align: center;
    font-size: 0.26rem;
    padding-top: 0.2rem;
  }
  .tite {
    padding: 0.3rem 0.35rem 0 0.3rem;
    font-weight: bold;
    font-size: 0.35rem;
  }
  .animate_active {
    transition: all 0.2s ease-in-out;
    /* transition: transform .2s cubic-bezier(0,0,.25,1),-webkit-transform .2s cubic-bezier(0,0,.25,1); */
    /* 这是滑入划出的动画 */
    min-height: 3rem;
    max-height: 5rem;
  }
}
</style>