<template>
    <div class="circle">
        <div class="circle_box">
            <div class="circle_nav_box">
                <div class="circle_nav">
                    <div class="circle_nav_picture">
                        <img src="../../../assets/20211008110104.jpg" alt="">
                    </div>
                    <span>今日指南今日指南今日指南今日指南今日指南今日指南今日指南今日指南今日指南今日指南</span>
                </div>
            </div>
            <div class="circle_nav_box">
                <div class="circle_nav">
                    <div class="circle_nav_picture">
                         <img src="../../../assets/20211008110104.jpg" alt="">
                         <span>今日指南今日指南今今日指南今日指南今日指南今日指南今日指南今日指南今日指南今日指南今日指南今日指南</span>
                    </div>
                </div>               
            </div>
            <div class="circle_nav_box">
                <div class="circle_nav">
                    <div class="circle_nav_picture">
                        <img src="../../../assets/20211008110104.jpg" alt="">
                    </div>
                    <span>今日指南今日指南今日指南</span>
                </div>
            </div>
            <div class="circle_nav_box">
                <div class="circle_nav">
                    <div class="circle_nav_picture">
                         <img src="../../../assets/20211008110104.jpg" alt="">
                         <span>今日指南今日指南今</span>
                    </div>
                </div>               
            </div>                    
        </div>
    </div>
</template>

<script>
export default {
    
}
</script>

<style scoped lang="scss">
    .circle{
        .circle_box{
        //     display: flex;  
        //   flex-flow:column wrap;
        //   height: 100vh;
            margin: 10px;
            column-count: 2;
            column-gap: 10px;
            .circle_nav_box{
                margin-bottom: 10px;
                .circle_nav{
                    margin: 0 .1rem;
                    .circle_nav_picture{
                        width: 100%;
                        height: 3.5rem;
                        img{
                            width: 100%;
                            height: 100%;
                        }
                    }
                }
            }
        }
    }
</style>