<template>
    <div class="Customerservice">
        <div class="consult_contact" @click="Customerservice()">
            <div class="consult_wrap">
                <a rel="nofollow" target="_blank">
                    <div class="box ball">
                        <img src="../../assets/image/ball.png"  alt="">
                    </div>
                    <div class="box staff_img">
                        <div class="picbox" id="picbox">
                            <div class="boxs front">
                                <img src="../../assets/image/bg_00.png" alt="">
                            </div>
                            <div class="boxs back">
                                 <p>联系老师</p>
                            </div>                            
                        </div>
                    </div>                    
                    <div class="box bg-1">
                        <img src="../../assets/image/bg_1.png"  alt="">
                    </div>                      
                    
                    <!-- <img src="../../assets/image/bg_2.png" class="bg-2" alt="">
                    <img src="../../assets/image/bg_3.png" class="bg-3" alt=""> -->
                </a>
            </div> 
        </div> 
    </div>
    
</template>
<script>
export default {
    data() {
        return {
           timer:'', 
        }
    },
    methods: {
        Customerservice(){
            window.location.href = "https://work.weixin.qq.com/kfid/kfce89da5aef2a89573?enc_scene=ENCBoSNJ9a7HszPWeBNnEBmStzCNbn2X2Vpjma3xzx3F8rv"
                                    
        },
        
        kefu(){
            let _this = this
            let scrollTip = document.querySelector("#picbox");
            clearInterval(_this.timer); 
            if(scrollTip){
                scrollTip.classList.add('picboxa');
                //监听动画是否结束
                scrollTip.addEventListener('animationend',function(){
                //     //动画结束，移除动画的样式类
                    scrollTip.classList.remove('picboxa');
                    // scrollTip.classList.add('picboxb');
                //     //延时1秒，再将动画加入
                })
                _this.timer = setInterval(()=>{  // 自动轮播
                        _this.kefu()
                }, 10000)
            }
        }
    },
    mounted() {
        this.kefu()
    },

    
}
</script>
<style lang="scss" scoped>
    .Customerservice{
        .service{
            width: 1.1rem;
            height: 1.1rem;
            background: chocolate;
            border-radius: 50%;
            position: fixed;
            bottom: 2.5rem;
            right: 0;
        }
        .consult_wrap .staff_img{
            width: .9rem; 
            height: .9rem;
           
        }
        .consult_wrap .staff_img .img{
            width: 100%; 
            height: 100%;
            position: absolute;
            backface-visibility: hidden; 
        }
        .consult_wrap .staff_img .text{
            position: absolute;
            width: 100%; 
            height: 100%;
            color: white;
            text-align: center;
            transform: rotateY(180deg);
            backface-visibility: hidden;            
        }
        .consult_wrap .box img{
            width: 100%; 
            height: 100%;
        }
        .consult_contact{position:fixed;bottom:15%;right:0;width:1.2rem;height:1.2rem;cursor:pointer;}
        .consult_wrap{position:relative;width:1.2rem;height:1.2rem;}
        .consult_contact .box{position:absolute;left:50%;top:50%;-webkit-transform:translate3d(-50%, -50%, 0);transform:translate3d(-50%, -50%, 0);}
        .consult_contact .ball img{
            width: .15rem;
            height: .15rem;
            display: block;
            margin-top: 50%;
            transform: translateY(-50%);           
        }
        @keyframes ballRotatea{
            0%{transform:rotateY(0deg);}
            50%{transform:rotateY(180deg);}
            50%{transform:rotateY(-180deg);}
            100%{transform:rotateY(0);}
      
        }
        @keyframes ballRotateb{
            0%{transform:rotateY(180deg);}
            100%{transform:rotateY(0deg);}
           
        }
        @keyframes ballRotatec{
            0%{transform:rotateY(180deg);}
            100%{transform:rotateY(0deg);}
           
        }
        @keyframes ballRotate{
            0%{transform:rotate(0);}
            100%{transform:rotate(360deg);}
            
        }
        .consult_contact .ball{
            top: 50%;
            width: 1.05rem;
            height: 1.05rem;
            transform-origin: 0 0; 
            animation:ballRotate 2.5s infinite linear;
            z-index:9999;
        }
        @keyframes bg2Animation{0%{opacity:0;}
            50%{opacity:1;}
            100%{opacity:0;}
        }
        .consult_contact .bg-1{width: 1.2rem; height: 1.2rem;}
        .consult_contact .bg-2{animation:bg2Animation 2.5s infinite linear;}
        .consult_contact:hover .tip{display:block;}        
    }
 .picbox{
    width: 100%; 
    height: 100%;
    position:relative;
     /*父元素里面包含需要变换的内容，所以设置为3D变换*/
     -webkit-transform-style: preserve-3d;
     -moz-transform-style: preserve-3d;
     -ms-transform-style: preserve-3d;
     transform-style: preserve-3d;
     transition:1.5s;
     /*设置在1.5s内完成变换*/
 }
 .picboxa{
     animation:ballRotatea 5s ;
 }
.picboxb{
     animation:ballRotateb 5s ;
 }
 .boxs{
     width: 100%; 
     height: 100%;
     position:absolute
     }
.boxs p{
    height: 100%;
    color: #fff;
    font-size: .26rem;
    text-align: center;
    width: 80%;
    margin: 0 auto;
    padding-top: 10%;
}
 .back{
     -webkit-transform: rotateY(180deg);
     -ms-transform: rotateY(180deg);
     -o-transform: rotateY(180deg);
     transform: rotateY(180deg);
     background: #3464ff;
     border-radius:50%;
     overflow: hidden;
 }
 .back h2{
     color: white;
     text-align: center;
 }
</style>