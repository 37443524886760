<template>
    <div class="Cardrotation">
        <transition name="fade">
            <div class="swiper" @touchend='end' @touchstart='start' @touchmove='move' >
              <div class="img" @click="chooseItem(item,index)" v-for="(item, index) in imgs" :style="config5[index]" :key="item.cover">
                <img :src="item.cover" style="width: 100%; height: 100%;">
                <div class="text">{{item.id}}</div>
              </div>
            </div>
    
        </transition>
    </div>
</template>
<script>
export default {
    name: 'zt',
    data:function() {
        return {
            loading: true,
            currentIndex: 3, //当前中间imgs数组中index
            centerInfo: '', // 当前中间信息
            startX: '',
            endX: '',
            imgs: [
                {
                    id: '莱因哈特1',
                    index: 0,
                    cover:
                        'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fpic1.win4000.com%2Fmobile%2F2020-07-08%2F5f055e260a19d.jpg&refer=http%3A%2F%2Fpic1.win4000.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=jpeg?sec=1644196744&t=551b6d432a58de9c0bd51247c494d228'
                },
                {
                    id: '安娜2',
                    index: 1,
                    cover:
                        'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fpic1.win4000.com%2Fmobile%2F2019-01-15%2F5c3d48182105e.jpg&refer=http%3A%2F%2Fpic1.win4000.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=jpeg?sec=1644196744&t=6368fd08a5acae22546c3e823c270866'
                },
                // {
                //     id: '卢西奥3',
                //     index: 2,
                //     cover:
                //         'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fhbimg.b0.upaiyun.com%2Fee7b88130a7debaecbcacc1bf01f419a5c7b950048af8-0oIIpv_fw658&refer=http%3A%2F%2Fhbimg.b0.upaiyun.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=jpeg?sec=1644196744&t=61ea0ab1eebcb258a0b9c36bd9e887c9'
                // },
                // {
                //     id: 'DVA4',
                //     index: 3,
                //     cover:
                //         'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fc-ssl.duitang.com%2Fuploads%2Fitem%2F202007%2F06%2F20200706230613_ikvtn.thumb.1000_0.jpg&refer=http%3A%2F%2Fc-ssl.duitang.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=jpeg?sec=1644196744&t=b8b310f3a4e900b2bbc92883890f4d83'
                // },
                // {
                //     id: '莫伊拉5',
                //     index: 4,
                //     cover:
                //         'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fhbimg.b0.upaiyun.com%2Fef97425216fe00623a36462ba3ba9791ea8f1b0a8498f-5tEtfI_fw658&refer=http%3A%2F%2Fhbimg.b0.upaiyun.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=jpeg?sec=1644196744&t=61efefc449bab5cbc03cff405bb05cb7'
                // },
                // {
                //     id: '裂空6',
                //     index: 5,
                //     cover:
                //         'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fc-ssl.duitang.com%2Fuploads%2Fitem%2F201205%2F11%2F20120511142238_wuydQ.thumb.400_0.jpeg&refer=http%3A%2F%2Fc-ssl.duitang.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=jpeg?sec=1644196744&t=2a17e35f3e42e9c95dfa4a436a5e91eb'
                // },
                // {
                //     id: '麦克雷7',
                //     index: 6,
                //     cover:
                //         'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fc-ssl.duitang.com%2Fuploads%2Fitem%2F201607%2F13%2F20160713191114_5y4ZS.thumb.1000_0.jpeg&refer=http%3A%2F%2Fc-ssl.duitang.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=jpeg?sec=1644196744&t=5a34fa98df0e714abf0b62a054bedb1e'
                // },
                // {
                //     id: '士兵76  8',
                //     index: 7,
                //     cover:
                //         'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fc-ssl.duitang.com%2Fuploads%2Fitem%2F201611%2F24%2F20161124233840_G4NWF.thumb.400_0.jpeg&refer=http%3A%2F%2Fc-ssl.duitang.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=jpeg?sec=1644196744&t=652c41c50fa46f21da0ebd908ec55909'
                // },
                // {
                //     id: '狂鼠9',
                //     index: 8,
                //     cover:
                //         'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fc-ssl.duitang.com%2Fuploads%2Fitem%2F202008%2F13%2F20200813191016_MnGYj.thumb.400_0.jpeg&refer=http%3A%2F%2Fc-ssl.duitang.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=jpeg?sec=1644196744&t=5240d61291ed6db71c9e6ebf1a273bb5'
                // },
                // {
                //     id: '死神 10',
                //     index: 9,
                //     cover:
                //         'https://img2.baidu.com/it/u=4022608379,1438432338&fm=26&fmt=auto'
                // },
                // {
                //     id: '禅雅塔 11',
                //     index: 10,
                //     cover:
                //         'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fpic1.win4000.com%2Fmobile%2F6%2F5980197ea30b5.jpg&refer=http%3A%2F%2Fpic1.win4000.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=jpeg?sec=1644197595&t=363085b1656a5fa61f41e587e3d6f0a4'
                // },
                // {
                //     id: '黑百合 12',
                //     index: 11,
                //     cover:
                //         'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fi0.hdslb.com%2Fbfs%2Farticle%2Fd5fd1a25166f71b214ce6796039ca1936d75600d.jpg&refer=http%3A%2F%2Fi0.hdslb.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=jpeg?sec=1644197595&t=4d14f8b769e4c82a0d227f19b760ea4b'
                // }
            ],
            previous: 0,
            config5: [
                // {
                //     id: '-A',
                //     position: 'absolute',
                //     width: '22%',
                //     height: '72%',
                //     top: '19.2%',
                //     left: '-22%',
                //     opacity: 0,
                //     zIndex: 0,
                //     transition: '.4s'
                // },
                // {
                //     id: 'A',
                //     position: 'absolute',
                //     width: '22%',
                //     height: '72%',
                //     top: '19.2%',
                //     left: '0%',
                //     opacity: 1,
                //     zIndex: 1,
                //     transition: '.4s'
                // },
                {
                    id: 'B',
                    position: 'absolute',
                    width: '35%',
                    height: '82%',
                    top: '14%',
                    left: '.2rem',
                    opacity: 1,
                    zIndex: 2,
                    transition: '.4s'
                },
                {
                    id: 'center',
                    position: 'absolute',
                    width: '50%',
                    height: '90%',
                    bottom: '0px',
                    left: '50%',
                    marginLeft: '-25%',
                    opacity: 1,
                    zIndex: 4,
                    transition: '.4s'
                },
                {
                    id: 'D',
                    position: 'absolute',
                    width: '35%',
                    height: '82%',
                    top: '14%',
                    right: '.2rem',
                    opacity: 1,
                    zIndex: 2,
                    transition: '.4s'
                },
                // {
                //     id: 'E',
                //     position: 'absolute',
                //     width: '22%',
                //     height: '72%',
                //     top: '19.2%',
                //     left: '78%',
                //     opacity: 1,
                //     zIndex: 1,
                //     transition: '.4s'
                // },
                // {
                //     id: 'E+',
                //     position: 'absolute',
                //     width: '22%',
                //     height: '72%',
                //     top: '19.2%',
                //     left: '100%',
                //     opacity: 0,
                //     zIndex: 0,
                //     transition: '.4s'
                // }
            ]
        };
    },
    methods: {
        // 获取数据
        async getData() {
            this.$nextTick(() => {
                this.loading = false;
            });
        },
        // 滑动上一个
        prev(index) {
            // this.imgs.unshift(this.imgs.pop());
            this.config5.push(this.config5.shift());
            this.currentIndex = this.currentIndex - 1;
            if (this.currentIndex < 0) {
                this.currentIndex = this.imgs.length - 1;
            }
            this.centerCard();
            this.centerIndex('prev');
        },
        // 滑动下一个
        next() {
            // this.imgs.push(this.imgs.shift());
            this.config5.unshift(this.config5.pop());
            this.currentIndex = this.currentIndex + 1;
            if (this.currentIndex > this.imgs.length - 1) {
                this.currentIndex = 0;
            }
            this.centerCard();
            this.centerIndex('next');
            // console.log(this.currentIndex);
        },
        // 开始移动端滑动屏幕
        start(event) {
            this.startX = event.changedTouches[0].clientX;
            this.startY = event.changedTouches[0].clientY;
        },
        // 连续滑动
        move(event) {
            this.endY = event.changedTouches[0].clientY;
            this.endX = event.changedTouches[0].clientX;
            this.stopDefault(event);
// 如果是滑动，注解（223行到231行）这段。如果是连续滑动，放开（223行到231行）注解
            this.interval = this.endX - this.startX;
            if (this.interval > 40) {
                this.startX = this.endX;
                this.prev();
            }
            if (this.interval < -40) {
                this.startX = this.endX;
                this.next();
            }
        },
        // 滑动
        end(event) {
// 如果是滑动，放开（236行到238行）的注解。如果是连续滑动，注解（236行到238行）
            // this.endY = event.changedTouches[0].clientY;
            // this.endX = event.changedTouches[0].clientX;
            // this.formatSwiper();
        },
        formatSwiper() {
            if (this.startX > this.endX) {
                console.log('左边滑动');
                if (this.startX > this.endX + 40) {
                    this.next();
                }
            } else {
                console.log('右边滑动');
                if (this.endX > this.startX + 40) {
                    this.prev();
                }
            }
        },
        // 阻止touchmove的横向默认事件（ios快捷操作会关闭页面）
        stopDefault(event) {
            let differenceY = this.endY - this.startY;
            let differenceX = this.endX - this.startX;
            if (Math.abs(differenceX) > Math.abs(differenceY)) {
                event.preventDefault();
            }
        },
        // 当前imgs在位置上的index（并非img数组的index）
        centerIndex(val) {
            if (val == 'prev') {
                for (let val of this.imgs) {
                    if (val.index == this.imgs.length - 1) {
                        val.index = 0;
                    } else {
                        val.index = val.index + 1;
                    }
                }
            } else {
                for (let val of this.imgs) {
                    if (val.index == 0) {
                        val.index = this.imgs.length - 1;
                    } else {
                        val.index = val.index - 1;
                    }
                }
            }
        },
        // 点击
        chooseItem(item, index) {
            console.log(item)
            // let cycles = item.index;
            // if (item.index < 3) {
            //     for (let i = 0; i < 3 - cycles; i++) {
            //         console.log(item.index);
            //         this.prev();
            //     }
            // } else if (item.index > 3) {
            //     for (let i = -1; i < item.index - 3; i++) {
            //         this.next();
            //     }
            // } else if (item.index == 3) {
            //     console.log('投票');
            // }
        },
        // 计算中间卡片信息
        centerCard() {
            this.centerInfo = this.imgs[this.currentIndex];
            this.$emit('centerInfo', this.centerInfo);
            // this.$emit('centerInfo', this.centerInfo);
            // console.log(this.imgs[2].id);
        },

        addCardStyle() {
            if (this.imgs.length > 7) {
                let addtime = this.imgs.length - 7;
                for (let i = 0; i < addtime; i++) {
                    console.log('add');
                    this.config5.push({
                        id: 'center',
                        position: 'absolute',
                        width: '45%',
                        height: '100%',
                        top: '0px',
                        left: '50%',
                        marginLeft: '-22.5%',
                        opacity: 0,
                        transition: '.1s'
                    });
                }
            }
        }
    },
    created() {
        this.getData();
        this.centerCard(); // 获取中间卡片信息
        this.addCardStyle();// 加入样式位置的index
    }
};
</script>

<style lang="scss" scoped>
    .Cardrotation{
        .swiper {
            width: 7.5rem;
            height: 400px;
            position: relative;
            overflow: hidden;

            .img {
                opacity: 0;
            }
            .text{
                position: absolute;
                bottom: 0;
                left: 50%;
                transform: translateX(-50%);
            }
        }
    }
</style>
