<template>
  <div>
    <router-view :class="{'ChildWeb': this.$route.meta.showTab}" />
    <loading v-if="LOADING" />
  </div>

</template>
<script>
import { mapGetters } from "vuex";
import loading from "@/components/loading.vue";
export default {
  data: function () {
    return {};
  },

  methods: {},
  mounted() {},
  created() {},
  components: {
    loading,
  },
  computed: {
    ...mapGetters({
      LOADING: "LOADING",
    }),
  },
};
</script>
<style scoped>
.ChildWeb {
  padding-bottom: 1.4rem;
}

</style>