import ajax from "@/api/index";

const defaultState = {
  wxinfo: localStorage.getItem("wxinfo"),
  token: localStorage.getItem("Token"),
  LoginUserInfo: localStorage.getItem("LoginUserInfo"),
  urlenterpriseid: localStorage.getItem("urlenterpriseid"),
  urlpeopleid: localStorage.getItem("urlpeopleid"),
  urlcompanyid: localStorage.getItem("urlcompanyid"),
  urlIndustryid : localStorage.getItem("urlIndustryid"), 
  StudentInfo:localStorage.getItem("StudentInfo"),
  StudentSystem:localStorage.getItem("StudentSystem"),
  address:localStorage.getItem("address"),
  Companylink:localStorage.getItem("Companylink"),
};

const actions = {
  getwxopid: function (context, { code, path , paths} ) {
    //获取公众号信息 openid
    let _this = this;
    let address = localStorage.getItem('address')
    let AfterPath
    if(address){
      AfterPath = address
    }else{
      AfterPath = path
    }
    ajax.getAjax("api/WxApi/GetUserInfo", { code: code }).then((res) => {
      if (res.data.meserror == "ok") {
        let unionid = res.data.mesdata.unionid;
        localStorage.setItem("wxinfo", JSON.stringify(res.data.mesdata));
        context.commit("wxinfo_update");
        if(AfterPath == '/manage/Workbench' || AfterPath == '/manage/Createaccount' || AfterPath == '/manage/Ambassador' || paths == '/manage/Createaccount'){
          context.dispatch("getwxtoke", unionid);
        }else{
          context.dispatch("getstudenttoken", unionid);
        }
      } else {
        
      }
    });
  },
  getwxtoke: function (context, unionid) {
    //获取登录信息 toke
    let _this = this;
    ajax
      .getAjax("api/EnterpriseLogin/token", { unionid: unionid })
      .then((res) => {
  
        if (res.data.meserror == "ok") {
          let Token = res.data.mesdata.token;
          
          localStorage.setItem("Token", Token);
          context.commit("token_update");
          
          if (!res.data.mesdata.people) {
            localStorage.setItem("LoginUserInfo", "");
          } else {
            localStorage.setItem("LoginUserInfo",JSON.stringify(res.data.mesdata.people));
            context.dispatch("Studentidentitys",res.data.mesdata.student.StudyId);
          }
          context.commit("LoginUserInfo_update");
          // if(localStorage.getItem("urlpeopleid")){
          //   context.dispatch("Lpeoplequery", urlpeopleid);
          // }
          // context.dispatch("Lpeoplequery", localStorage.getItem("urlpeopleid"));
        }
      });
    // .catch((res) => {
    //     console.log(res);
    // });
  },
  getstudenttoken:function(context, unionid){//获取学员信息
    let _this = this;
    ajax
      .getAjax("api/EnterpriseLogin/studenttoken", { unionid: unionid })
      .then((res) => {

        if (res.data.meserror == "ok") {
          let Token = res.data.mesdata.token;
          localStorage.setItem("Token", Token);
          if(res.data.mesdata.student){
            context.dispatch("Studentidentitys",res.data.mesdata.student.StudyId)
          }
          context.commit("token_update");
          if (!res.data.mesdata.student) {
            localStorage.setItem("StudentInfo", "");
          } else {
            localStorage.setItem(
              "StudentInfo",
              JSON.stringify(res.data.mesdata.student)
            );
          }
          context.commit("StudentInfo_update");
          // context.dispatch("Lpeoplequery", localStorage.getItem("urlpeopleid"));
        }
      });
  },


  seturlid: function (context, { urlenterpriseid, urlpeopleid , urlcompanyid , urlIndustryid}) {
    localStorage.setItem("urlenterpriseid", urlenterpriseid);
    localStorage.setItem("urlpeopleid", urlpeopleid);
    localStorage.setItem("urlcompanyid", urlcompanyid);
    if(urlpeopleid){
      context.dispatch("Lpeoplequery", urlpeopleid);
    }
    context.commit("urlid_update");
   
  },
  Lpeoplequery:function(context,urlpeopleid){

    let _this = this
    ajax.getAjax("api/EnterprisePeople/people",{id:urlpeopleid})
    .then( res=> { //根据id 查询业务员对象
        if(res.data.mescode == '0'){
            localStorage.setItem("urlIndustryid", res.data.mesdata.EnterpriseId);
            context.commit("UrlIndustryid_update");
        };
    });  
  },
  Studentidentitys:function(_context, StudyId){//根据学员id 获取学习系统学员信息

    ajax.getAjax("api/EnterpriseLogin/StudyStudent", {studyid:StudyId} )
    .then( res=> {
        if(res.data.meserror == 'ok'){
          localStorage.setItem(
            "StudentSystem",
            JSON.stringify(res.data.mesdata)
          );
          _context.commit("StudentSystem_update");
        }
    });
  },
  setLoginUserInfo:function(context,people){
    localStorage.setItem(
      "LoginUserInfo",
      JSON.stringify(people)
    );
    context.commit("LoginUserInfo_update");
  },
  setStudentInfo:function(context,people) {
    localStorage.setItem(
      "StudentInfo",
      JSON.stringify(people)
    );
    context.commit("StudentInfo_update");
  },
  Address:function(context,Address){
    localStorage.setItem('address', Address);
    context.commit("Address_update");
  },
  Companylink:function(context,Enterprise){
    localStorage.setItem('Companylink', Enterprise);
    context.commit("Companylink_update");
  }
};

const mutations = {
  wxinfo_update: (state) => {
    state.wxinfo = localStorage.getItem("wxinfo");
  },
  token_update: (state) => {
    state.token = localStorage.getItem("Token");
  },
  LoginUserInfo_update: (state) => {
    state.LoginUserInfo = localStorage.getItem("LoginUserInfo");
  },
  urlid_update: (state) => {
    state.urlenterpriseid = localStorage.getItem("urlenterpriseid");
    state.urlpeopleid = localStorage.getItem("urlpeopleid");
    state.urlcompanyid = localStorage.getItem("urlcompanyid");
    
  },
  StudentInfo_update: (state) => {
    state.StudentInfo = localStorage.getItem("StudentInfo");
  },
  UrlIndustryid_update: (state) => {
    state.urlIndustryid = localStorage.getItem("urlIndustryid");
  },

  StudentSystem_update: (state) => {
    state.StudentSystem = localStorage.getItem("StudentSystem");
  },
  Address_update: (state) => {
    state.address = localStorage.getItem("address");
  },
  Companylink_update: (state) => {
    state.Companylink = localStorage.getItem("Companylink");
  }
};

const getters = {
  wxinfo: (state) => state.wxinfo,
  token: (state) => state.token,
  LoginUserInfo: (state) => state.LoginUserInfo,
  urlenterpriseid: (state) => state.urlenterpriseid,
  urlpeopleid: (state) => state.urlpeopleid,
  urlcompanyid: (state) => state.urlcompanyid,
  urlIndustryid: (state) => state.urlIndustryid,
  StudentInfo: (state) => state.StudentInfo,
  StudentSystem:(state) => state.StudentSystem,
  address:(state) => state.address,
  Companylink:(state) => state.Companylink,
};

export default {
  state: defaultState,
  getters,
  actions,
  mutations,
};
