import ajax from '@/api/index'

const defaultState = {
    LOADING: false,

};

const actions = {


};

const mutations = {
    showLoading(state) {
        state.LOADING = true
    },
    hideLoading(state) {
        state.LOADING = false
    }
}
const getters = {
    LOADING: state => state.LOADING,

};
export default {
    state: defaultState,
    getters,
    mutations,
};