<template>
  <div class="HomePage" id="HomePage">
    <div class="HomePageBox">
      <Banner action="home" @classFun="classFun"></Banner>
      <!-- <Navigation></Navigation> -->
      <Examinationtime></Examinationtime>
      <Tabnav></Tabnav>
      <Customerservice></Customerservice>

    </div>
  
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Banner from "@/components/Student/banner/banner.vue";
import Examinationtime from "@/components/Student/banner/Examinationtime.vue";
import Navigation from "@/components/Student/navigation/navigation.vue";
import Tabnav from "@/components/Student/navigation/tabnav.vue";

import Customerservice from "@/components/Customerservice/Customerservice.vue"
export default {
  components: {
    
    Navigation,
    Tabnav,
    Customerservice,
    Examinationtime,
    Banner,   
  },
  computed: {
    ...mapGetters({
            wxinfo: "wxinfo",
            token: "token",
            LoginUserInfo: "LoginUserInfo",
            urlenterpriseid: "urlenterpriseid",
            urlpeopleid: "urlpeopleid",
            StudentInfo: "StudentInfo",
    }),
  },
  data(){
    return {
      bannerlist: [
        // { image: "https://storage.360buyimg.com/jdc-article/NutUItaro34.jpg" },
        // { image: "https://storage.360buyimg.com/jdc-article/NutUItaro2.jpg" },
        // { image: "https://storage.360buyimg.com/jdc-article/welcomenutui.jpg" },
        // { image: "https://storage.360buyimg.com/jdc-article/fristfabu.jpg" },
      ],

    };
  },

  methods: {
    classFun(e){
      var ElementName = document.getElementById("HomePage") ;
      if(e){
        // document.getElementsByTagName("body")[0].className="bannerbody";
        // ElementName.className="bannerbody"
        ElementName.classList.add("bannerbody")
      }else{
        // document.body.removeAttribute("class","bannerbody");
        ElementName.classList.remove("bannerbody");
      }
    },
   


  },
  created() {
    let _this = this;
    
   

  },
  mounted() { 
    let _this = this;

  },
};

</script>
<style>
  body{
    overflow: hidden;
  }
  /* .bannerbody{
    overflow: hidden;
  } */

  .HomePage {
    overflow: auto;
    height: calc(100vh - 1.2rem) ;
  }
</style>