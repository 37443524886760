<template>
    <div class="Navigation">
        <div class="Navigation-nav">
            <div class="Navigation-nav-x" v-for="(item , index) in navigation" :key='index' @click='Turnpages(item.path)'>
                <div class="Navigation-top">
                    <img :src="item.image" alt="">
                </div>
                <div class="Navigation-bottom">{{item.name}}</div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    data:function(){
        return{
            navigation:[
                {
                    name:'首页', 
                    image:'https://www.17sucai.com/preview/2318522/2021-04-08/dome010/img/item_01.png' , 
                    path:'1'
                },
                {
                    name:'首页', 
                    image:'https://www.17sucai.com/preview/2318522/2021-04-08/dome010/img/item_02.png' , 
                    path:'2'
                },
                {
                    name:'首页', 
                    image:'https://www.17sucai.com/preview/2318522/2021-04-08/dome010/img/item_03.png' , 
                    path:'3'
                },
                {
                    name:'首页', 
                    image:'https://www.17sucai.com/preview/2318522/2021-04-08/dome010/img/item_04.png' , 
                    path:'4'
                },

            ],
        }
    },
    methods:{
        Turnpages:function(e){
            // console.log(e)
        }
    },
    created(){
      
    }
}
</script>

<style lang="scss" scoped>
    .Navigation{
        background: #fff;
        .Navigation-nav{
            padding: .2rem 0;
            .Navigation-nav-x{
                width: calc(100%/4);
                display: inline-block;
                text-align: center;
                margin: .1rem 0;
                .Navigation-top{
                    width: 1.4rem;
                    height: 1.4rem;
                    margin: 0 auto;
                }
                .Navigation-bottom{
                    font-size: .28rem;

                }
            }
        }
    }
</style>