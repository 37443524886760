<template>
    <div class="Examinationtime">
        <div class="FreeTimeBox">
            <span class="FreeTimeBox_time">今天是 {{PresentTime}}</span>
            <span class="FreeTimeBox_count">距离2024年执业药师考试还有</span>
            <span class="FreeTimeBox_Examinationtime">考试时间 {{ExaminationTime}}</span>
        </div>
        <div class="FreeTimeBox_Days_box">
            <div class="FreeTimeBox_Days_box_1">
                <span class="FreeTimeBox_Days">{{DaysRemaining}}</span>
                <span class="FreeTimeBox_Day">天</span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return {
            DaysRemaining:'',
            ExaminationTime:'2024-10-19 / 2024-10-20',
            timer: null ,
            PresentTime:'',
            nowItem : new Date().getTime(),
            endItem : new Date('2024/10/19 00:00:00').getTime(),
        };
    },

    methods: {
      setTimer(){
        let _this = this;
        _this.timer = setInterval(()=>{
            let nowItem = new Date().getTime();
            let time = _this.endItem - nowItem ;
            _this.DaysRemaining = _this.HelperCommon.formateTimeStamp(time).day + 1 ;
            _this.PresentTime = _this.HelperCommon.getTodayweekTime() ;
          
        },1000)
       

      }
    },
    created() {
        let _this = this;
        _this.DaysRemaining = _this.HelperCommon.formateTimeStamp(Number(_this.endItem) - Number(_this.nowItem)).day + 1 
        _this.PresentTime = _this.HelperCommon.getTodayweekTime()
        _this.setTimer()
    },
    mounted() {
      let _this = this;

    },
    beforeUnmount() { 
        if(this.timer) { //如果定时器还在运行 或者直接关闭，不用判断
            clearInterval(this.timer); //关闭
        }
    }
}
</script>


<style lang="scss" scoped>
    .Examinationtime{
        display: flex;
        padding: .3rem .4rem;
        background: #fff;
        border-bottom: 1px solid rgb(243, 243, 243);
        .FreeTimeBox{
            flex: 1;
            span{
                display: block;
                padding: .04rem;
                font-size: .3rem;
                
            }
            .FreeTimeBox_time{
                font-size: .26rem;
            }
            .FreeTimeBox_count{
                font-weight:610 ;
            }
            .FreeTimeBox_Examinationtime{
                font-size: .24rem;
                color: rgb(150, 150, 150);
            }
            
        }
        .FreeTimeBox_Days_box{
            width: 2.3rem;
            font-size: 1.1rem;
            height: 100%;
            .FreeTimeBox_Days_box_1{
                 display: table;
                 width: 100%;
                 
                .FreeTimeBox_Days{
                    display: table-cell;
                    vertical-align: middle;
                    font-weight: 600;
                    text-align: right
                }
                .FreeTimeBox_Day{
                    font-size: .26rem;
                    padding: 0 .1rem;
                    // vertical-align: bottom;
                }
            }


        }
        // .FreeTimeBox_day{
        //     width: .4rem;
        //     font-size: .26rem;
        //     .FreeTimeBox_Day_box_1{
        //         display: table;
        //         width: 100%;
        //         height: 100%;
        //         .FreeTimeBox_Day{
        //             display: table-cell;
        //             vertical-align: bottom;
        //             padding: .25rem 0;
        //         }
        //     }
        // }
    }
</style>