import { Toast } from '@nutui/nutui';
import ajax from '@/api/index'
const phoneverification = {
    state: () => ({
        second: 60 ,
        btnValue:'获取验证码',
        disableds:false,
        Rvcode:'',
    }),
    mutations: { 
        Second:function(state,time){
            state.second = time
        },
        BtnValue:function(state,value){
            state.btnValue = value
        },
        Disableds:function(state,disableds){
            state.disableds = disableds
        },
        Rvcode:function(state,Rvcode){
            state.Rvcode = Rvcode
        }
    },
    actions: { 
        Obtain:function(context,UserPhone){
            let _this = this;
            let  myreg = /^(((13[0-9]{1})|(14[0-9]{1})|(15[0-9]{1})|(16[0-9]{1})|(17[0-9]{1})|(18[0-9]{1})|(19[0-9]{1}))+\d{8})$/;
            if(UserPhone==''){
                Toast.text('手机号码不能空');
                return
            } else if(UserPhone.length < 11 || !myreg.test(UserPhone)){
                Toast.text('请输入正确11位手机号码');
                return;
            }else
            {
                Toast.text('发送成功');
                context.commit('Disableds', true)
                context.dispatch('getSendverification',UserPhone)
            }
            let setTimer = setInterval(() => {
                let second = context.state.second - 1;
                let btnValue = second +' '+ '秒';
                context.commit('Second', second)
                context.commit('BtnValue', btnValue)
                if (context.state.second <= 0) {
                    context.commit('Second',60); 
                    context.commit('BtnValue' , '获取验证码');
                    context.commit('Disableds', false);
                    clearInterval(setTimer)
                }
            }, 1000)        
        },
        getSendverification:function(context,Phone){
            let _this = this;
            ajax.getAjax("api/EnterpriseLogin/phonecode",{phone:Phone}).then(res=>{   
                context.commit('Rvcode', JSON.stringify(res.data.mesdata));
            })
        },  
    },
    getters: { 
        

    }
  }
  export default phoneverification
  
