import store from '../../store';
import HelperCommon from '../HelperCommon'

const datanullsource = {
    getData(key) {
        
        let keyboy = this.data.find(item => item.key == key);
        if (!keyboy) {
            keyboy = this.data.find(item => item.key == "default");
        }
        return keyboy;
    },

    data: [{
            key: "default",
            imgsrc: "../../assets/image/datanull.jpg",
            text: "未找到数据信息",
            linktitle: "",
            routerpate: "",
        },
        {
            key: "people",
            imgsrc: "",
            text: "未找到用户信息",
            linktitle: "点击此处邀请业务员入驻",
            popupdata: {
                position: "bottom",
                show: false,
                des: [
                    "点击>>>复制链接",
                    "粘贴至微信发送或添加至企微工作台。",
                    "员工点击进入即可入驻",
                ],
                link: 'https://'+ location.host + "/manage/Createaccount?enterprise=",
                key: "people"
            }
        },
        {
            key: "student",
            imgsrc: "",
            text: "未找到学员信息",
            linktitle: "点击此处邀请邀请学员注册",
            popupdata: {
                position: "bottom",
                show: false,
                des: [
                    "点击>>>复制链接",
                    "粘贴至微信发送或添加至企微工作台。",
                    "学员点击进入即可注册",
                ],
                link: 'https://' + location.host + "/manage/Createstudent?people=",
                key: "student"
            }

        }
    ],

}





export default datanullsource;