var isDialog = {
    init:function (data, callback) {
        this.template(data)
        if(data.type == '0'){
            var _id = document.getElementById('pop_fixed');
            var cancel = _id.querySelector('.pop_cancel');
            var confirm = _id.querySelector('.pop_confirm');
            cancel.addEventListener('click', function () {
                callback && callback({ 'type': 0 });
                _id.classList.remove("on");
                setTimeout(function(){
                    _id.remove();
                },300)
            });
            confirm.addEventListener('click', function () {
                callback && callback({ 'type': 1 });
                _id.classList.remove("on");
                setTimeout(function(){
                    _id.remove();
                },300)
            });
        }else if(data.type == '1'){
            var _id = document.getElementById('pop_fixed');
            var cancel = _id.querySelector('.pop_cancel');
            cancel.addEventListener('click', function () {
                callback && callback({ 'type': 0 });
                _id.classList.remove("on");
                setTimeout(function(){
                    _id.remove();
                },300)
            });
        }
    },
    open:function (data, callback) {
        this.init(data, callback)
    },
    close:function(_id){
        _id.classList.remove("on");
        setTimeout(function(){
            _id.remove();
        },300)
    },
    template:function (data) {
        if(data.type == '0'){
            var title = data.title || "";
            var content = data.content || "";
            var buttons = data.buttons || ['取消', '确定'];
            var html = 
                '<div class="pop_fixed" id="pop_fixed">' +
                    '<div class="pop_box">' + 
                        '<div class="pop_title">' + title + '</div>'+
                        '<div class="pop_content">' + content + '</div>'+
                        '<div class="pop_footer">'+
                            '<div class="pop_btn pop_cancel">'+ buttons[0] +'</div>'+
                            '<div class="pop_btn pop_confirm" id="pop_confirm">'+ buttons[1] +'</div>'+
                        '</div>'+
                    '</div>'+
                '</div>';
            document.getElementsByTagName("body")[0].insertAdjacentHTML("beforeend", html);
            setTimeout(function(){
                document.getElementById('pop_fixed').classList.add("on");
            },100)
        }else if(data.type == '1') {
            var title = data.title || "";
            var content = data.content || ""; 
            var buttons =  data.buttons ;
            var html = 
                '<div class="pop_fixed" id="pop_fixed">' +
                    '<div class="pop_box">' + 
                        '<div class="pop_title">' + title + '</div>'+
                        '<div class="pop_content">' + content + '</div>'+
                        '<div class="pop_footer">'+
                            '<div class="pop_btn pop_cancel">'+ buttons +'</div>'+
                        '</div>'+
                    '</div>'+
                '</div>';
            document.getElementsByTagName("body")[0].insertAdjacentHTML("beforeend", html);
            setTimeout(function(){
                document.getElementById('pop_fixed').classList.add("on");
            },100)
        }
    }
}
export default isDialog;