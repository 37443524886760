<template>
  <transition name="down">
      <div class="xtx-message" :style="style[type]" v-if="isShow">
      <!-- 上面绑定的是样式 -->
      <!-- 不同提示图标会变 -->
      <i class="iconfont" :class="style[type].icon"></i>
      <span class="text">{{message}}</span>
      </div>
  </transition>
</template>
<script>
import { onMounted, ref } from 'vue-demi'
export default {
  name: 'XtxMessage',
  props: {
    message: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      // warn 警告  error 错误  success 成功
      default: 'warn'
    }
  },
  setup () {
    // 定义一个对象，包含三种情况的样式，对象key就是类型字符串
    const style = {
      warn: {
        icon: 'icon-warning',
        color: '#fff',
        backgroundColor: 'rgba(0, 0, 0, .6)',      },
      error: {
        icon: 'icon-shanchu',
        color: '#fff',
        backgroundColor: 'rgba(0, 0, 0, .6)',      },
      success: {
        icon: 'icon-queren2',
        color: '#fff',
        backgroundColor: 'rgba(0, 0, 0, .6)',
        // borderColor: 'rgb(225, 243, 216)'
      }
    }

    const isShow = ref(false)
    onMounted(() => {
      isShow.value = true
    })
    return { style, isShow }
  }
}
</script>
<style scoped lang="scss">
// 动画效果
.down {
  &-enter {
    &-from {
      transform: translateY(-100px);
      opacity: 0;
    }
    &-active {
      transition: all 0.5s;
    }
    &-to {
      transform: none;
      opacity: 1;
    }
  }
}
.xtx-message {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  z-index: 9999;
  padding: 10px 10px;
  border-radius: 4px;
  font-size: .28rem;
//   i {
//     margin-right: 4px;
//     vertical-align: middle;
//   }
//   .text {
//     vertical-align: middle;
//   }
}
</style>