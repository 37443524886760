

  var loading = {
        
        start: function (optionsT) {
            
            $(".loadingPage").remove();
            var defaults = {
                opacity: 1, // loading页面透明度
                backgroundColor: '#FFF', // loading页面背景色
                borderColor: '#bbb', // 提示边框颜色
                borderWidth: 1, // 提示边框宽度
                borderStyle: 'solid', // 提示边框样式
                loadingTips: '加载中', // 提示文本
                TipsColor: '#666', // 提示颜色
                delayTime: 1000, // 页面加载完成后，页面渐出速度
                zindex: 999, // loading页面层次
                sleep: 0, // 设置挂起，等于0时无需挂起
            }
    
            var options = $.extend(defaults);
           
            // 获取页面可见区域宽高
            var _pageHeight = document.documentElement.clientHeight,
                _pageWidth = document.documentElement.clientWidth;
                
            // loading Html自定义内容
            var _LoadingHtml =  
                '<div class="loadingPage">'+   
                    '<div class="loadingboxS">'+
                        '<div class="loadingboxX">'+
                            '<div class="loadingbox">'+
                                '<div class="loading_Q">'+
                                    // '<svg viewBox="0 0 50 50" class="loading-svg_U">'+
                                    //     '<circle cx="25" cy="25" r="20" fill="none" class="paths"></circle>'+
                                    //     '<circle cx="25" cy="25" r="20" fill="none" class="path"></circle>'+
                                        
                                    // '</svg>'+
                                    // '<svg viewBox="0 0 50 50" class="loading-svg_D">'+
                                    //     '<circle cx="25" cy="25" r="20" fill="none" class="paths"></circle>'+
                                    // '</svg>'+
                                    // '<div class="loader"></div>'+
                                    '<div class="sk-fading-circle">'+
                                        '<div class="sk-circle1 sk-circle"></div>'+
                                        '<div class="sk-circle2 sk-circle"></div>'+
                                        '<div class="sk-circle3 sk-circle"></div>'+
                                        '<div class="sk-circle4 sk-circle"></div>'+
                                        '<div class="sk-circle5 sk-circle"></div>'+
                                        '<div class="sk-circle6 sk-circle"></div>'+
                                        '<div class="sk-circle7 sk-circle"></div>'+
                                        '<div class="sk-circle8 sk-circle"></div>'+
                                        '<div class="sk-circle9 sk-circle"></div>'+
                                        '<div class="sk-circle10 sk-circle"></div>'+
                                        '<div class="sk-circle11 sk-circle"></div>'+
                                        '<div class="sk-circle12 sk-circle"></div>'+
                                    '</div>'+
                                '</div>'+
                            '</div>'+
                            '<div class="_title"> '+ (optionsT ? optionsT : options.loadingTips) + '</div>'+
                        '</div>'+
                    '</div>'+
                  
                '</div>'

            // 呈现Loading效果
            $('body').append(_LoadingHtml);
           
            // 获取loading提示框宽高
            // var _LoadingTipsH = document.getElementById("loadingTips").clientHeight,
            // _LoadingTipsW = document.getElementById("loadingTips").clientWidth;
    
            // //计算距离，让loading提示框保持在屏幕上下左右居中
            // var _LoadingTop = _pageHeight > _LoadingTipsH ? (_pageHeight - _LoadingTipsH) / 2 : 0,
            // _LoadingLeft = _pageWidth > _LoadingTipsW ? (_pageWidth - _LoadingTipsW) / 2 : 0;
    
            // $("#loadingTips").css({
            //     "left": _LoadingLeft + "px",
            //     "top": _LoadingTop + "px"
            // });
    
            //监听页面加载状态
            // document.onreadystatechange = PageLoaded;
    
            //当页面加载完成后执行
            // function PageLoaded() {
               
            //     if (document.readyState == "complete") {
            //         var loadingMask = $('#loadingPage');
    
            //         setTimeout(function () {
            //             loadingMask.animate(
            //                 {
            //                     "opacity": 0
            //                 },
            //                 options.delayTime,
            //                 function () {
            //                     $(this).hide();
            //                 }
            //             );
            //         }, options.sleep);
            //     }
            // }
        },
        end: function () {
            $(".loadingPage").remove();
        }
    }
    
    // setTimeout(function () {
    //     $.loading.end();
    // }, 5000)
    export default loading;




















