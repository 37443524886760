import axios from 'axios';
// import { Toast } from "@nutui/nutui";
import store from '../store';
import loading from '../util/loading/loading'

let requestingCount = 0;
var toast;
const handleRequestLoading = (res) => {
    if (!requestingCount) {
        if(res == undefined){
            loading.start()
        }
        // store.commit('showLoading')
            // console.log(Toast);
            // toast = Toast.loading('加载中...', {
            //     cover: true,
            //     duration: 0
            // });
            // console.log(toast);
            // toast.hide();
            // toast = Toast.loading('加载中...', {
            //     cover: true,
            //     duration: 0
            // });

    }
    requestingCount++
}
const handleResponseLoading = () => {
    requestingCount--
    if (!requestingCount) {
        loading.end()
        // store.commit('hideLoading')
    }
}
// let baseURL1= "https://crmapi.jingyaoshi.net/";
let baseURL= "/url";
let baseURL1= "https://crmapi.jingyaoshi.net/";
let baseURL2= "https://crm.jingyaoshi.net";
let baseURL3= "https://api.jingyaoshi.net";
let baseURL4= "https://zb.jingyaoshi.net";
let baseURL5= "https://pulic.jingyaoshi.net";
let baseURL6= "http://www.ttmd5.com";



const Crmserve = axios.create({
    timeout: 30000,
    // baseURL: "https://crmapi.jingyaoshi.net/"
});
 
const Crmserve1 = axios.create({
    baseURL: "/url",
    timeout: 30000,
});


Crmserve.interceptors.request.use(//正式
    function(config) {
        
        handleRequestLoading(config.loading);
        if(config.urlType == 'public'||config.urlType == 'zb' || config.urlType == 'api'){
      
            if(localStorage.getItem('publictoken') !== 'undefined'){
                
                config.headers= {
                    //"Accept": "text/plain; charset=utf-8",
                    //"Content-Type": "text/plain; charset=utf-8",
                    "Accept": "application/json, text/javascript, */*; charset=utf-8",
                    "Content-Type": "application/x-www-form-urlencoded; charset=utf-8 ; text/plain",
                    "X-Session-Token" : localStorage.getItem('publictoken')
                }
                
            }
            // if( localStorage.getItem('publictoken') !=='' || localStorage.getItem('publictoken') !== undefined || localStorage.getItem('publictoken') !== 'undefined'){
            //     // config.headers.auth = localStorage.getItem('publictoken')
            //     console.log(localStorage.getItem('publictoken'))
            // }
          
           
        }else
        // console.log(toast, new Date());
        if (localStorage.getItem('Token')) {
            config.headers.auth = localStorage.getItem('Token')
           
        }
        if(config.urlType == 'studid'){
            config.headers.auth = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ3aWQiOiJvUldJYzVoWGt6ZmFGMGdzakxoS0pmR0hzVTdNIiwiVGltZXMiOiIyMDIzLTAzLTE0VDEwOjE2OjU4Ljc2NzgzMjcrMDg6MDAifQ.MZKbZJ5h4uO8A5GMSVoavfKhq2WeAqBUbx-5miP0Gaw'
        }
        // config.data = JSON.stringify(config.data); 
        switch (config.urlType) {
            case 'api1':
                config.url = baseURL1 + config.url;
                break;
            case 'studid':
                config.url = baseURL1 + config.url;
                break;
            case 'api2':
                config.url = baseURL2 + config.url;
                break;
            case 'api':
                config.url = baseURL3 + config.url;
                break;

            case 'public':
                config.url = baseURL5 + config.url;
                break;
            case 'zb':
                config.url = baseURL4 + config.url;
                break;
            case 'md5':
                config.url = baseURL6 + config.url;
                break;
            case 'dl':
                config.url = baseURL + config.url;
                break;    
            default:
                config.url = baseURL1 + config.url;
        }
      
        return config;
    },
    function(err) {
        
        handleResponseLoading();
        return Promise.reject(err);
    }
);

Crmserve.interceptors.response.use(
    function(response) {
       
        handleResponseLoading();
        return response;
    },
    function(err) {
        handleResponseLoading();
        return Promise.reject(err);
    }
);


Crmserve1.interceptors.request.use(//跨域
    function(config) {
        handleRequestLoading();
        if (localStorage.getItem('Token')) {
            config.headers.auth = localStorage.getItem('Token')
            
        }
     
        return config;
    },
    function(err) {
        handleResponseLoading();
        return Promise.reject(err);
    }
);
Crmserve1.interceptors.response.use(//跨域
    function(response) {
        handleResponseLoading();
        return response;
    },
    function(err) {
        handleResponseLoading();
        return Promise.reject(err);
    }
);


const get = {

    
    getAjax1(url, params) { //get请求  用于获取数据。
        return Crmserve1.get(url, { params });
    },

    getAjax(url,params,type,loading) { //get请求  用于获取数据。
            
            const config = {
                method: 'GET',
                url: url,
                urlType:type,
                loading:loading,
            };
            if (params) {
                config.params = params;
            }
          
            return Crmserve(config);
    },

    postAjax1(url, data) { //post请求  用于提交数据（新建）、包括表单提交及文件上传。
        return Crmserve1.post(url, data);
    },
    postAjax(url, params,type,loading) { //post请求  用于提交数据（新建）、包括表单提交及文件上传。

        const config = {
            method: 'POST',
            url: url,
            urlType: type,
            loading:loading,
        };
        if (params) {
            config.data = params;
        }
        
        return Crmserve(config);
    },


    putAjax(url, data) { //put请求  用于更新数据（修改），将所有数据都推送到后端。
        return Crmserve.put(url, data);
    },

    patchAjax(url, data) { //patch请求  用于更新数据（修改），只将修改的数据推送到后端。
        return Crmserve.patch(url, data);
    },

    deleteAjax(url, data) { //delete请求  用于删除数据。
        return Crmserve.delete(url, data);
    },

    Crossdomain(url, data) { //跨域
        return axios.get(url, { params })
    },

    wxlogin(code) {
        return axios.get('http://crm.jingyaoshi.net/ExperienceInfomationTask/InfomationTask')
    },
    PostUpLoadUrl: "https://crmapi.jingyaoshi.net",
    WxloginURL: "https://crm.jingyaoshi.net/wxcode1.html",
    // WxloginURL: "https://crm.jingyaoshi.net/wxcodevm.html",
    Learningwebsite:"https://public.jingyaoshi.net",
   
}


export default get