
<template>
  <div class="home">
    <Navigationbar></Navigationbar>
  </div>
    
</template>

<script>
// @ is an alias to /src
import { mapGetters } from "vuex";
import Navigationbar from '@/components/Navigationbar.vue'
export default {
  name: 'Home',
  computed: {
    ...mapGetters({
        LoginUserInfo: "LoginUserInfo",
        wxinfo: "wxinfo",
    }),
  },
  data:function(){
    return{ 
      
    }
  },

  methods:{
    // LoginUserInfoFun:function(){
    //   console.log(JSON.parse(this.LoginUserInfo).IsAdmin )
    // }

  },
  mounted() {



  },
  created (){
    let _this = this
 
  },
  components: {
    Navigationbar
  }
}
</script>
<style lang="scss" scoped>

</style>
