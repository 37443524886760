<template>
  <div class="Tabnav">
    <div class="Tabnav-box">
      <div
        class="Tabnav-name"
        v-for="(item, index) in Tabnav"
        :key="index"
        @click="Tabswitch(item, index)"
        :class="{ active: index == curindex }"
      >
        {{ item.name }}
      </div>
    </div>
    <div class="Content-box">
      <div class="Content" v-if="contentid == '1'">1111</div>
      <div class="Content" v-if="contentid == '2'">
        <HomeCourse />
      </div>
      <div class="Content circle" v-if="contentid == '3'">
      </div>
    </div>
  </div>
</template>

<script>
import HomeCourse from "@/components/Student/Home/HomeCourse.vue";
import Circle from "../circle/circle.vue"
export default {
  components: {
    HomeCourse,
    Circle
  },
  data: function () {
    return {
      Tabnav: [
        // {
        //    name:'直播',
        //    val:'1'
        // },
        {
          name: "精品课程",
          val: "2",
        },
        // {
        //   name: "圈子",
        //   val: "3",
        // },
      ],
      curindex: "0",
      contentid: "2",
    };
  },
  methods: {
    Tabswitch: function (item, index) {
      let _this = this;
      _this.curindex = index;
      _this.contentid = item.val;
    },
  },
  created() {},
};
</script>

<style lang="scss" scoped>
.Tabnav {
  .Content-box {
    background: #fff;
    padding-bottom: 0.2rem;
    .Content {
      margin: 0 0.25rem;
      border-radius: 0.1rem;
    }
    .circle{
      margin: 0 !important;
    }
  }
  .Tabnav-box {
    background: #fff;
    padding: 0.1rem 0.1rem;

    border-bottom: 1px solid #eee;
    .Tabnav-name {
      display: inline-block;
      padding: 0.15rem 0.4rem;
      font-size: 0.31rem;
      color: rgb(177, 177, 177);
    }
    .active {
      color: #000;
      position: relative;
      font-weight: bold;
      // font-size: .33rem;
    }
    .active::after {
      content: "";
      position: absolute;
      width: 0.4rem;
      height: 0.05rem;
      border-radius: 0.1rem;
      background: coral;
      left: 50%;
      bottom: 0;
      transform: translate(-50%);
    }
  }
}
</style>