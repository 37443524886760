import { createRouter, createWebHistory } from 'vue-router'
import Workbench from '@/views/Workbench.vue'
import Courseselection from '@/views/Student/homepage/homepage.vue'
const routes = [{
        path: "/",
        name: 'test',
        meta: {
            title: '学员首页',
        },
        redirect: '/reception/homepage',
        // component: () =>  import ( /* webpackChunkName: "about" */ '@/views/Student/test.vue')
        // import ( /* webpackChunkName: "about" */ '@/studentpage/Courseselection.vue')
        component: Courseselection
    },
    {
        path: "/manage",
        name: 'manage',
        meta: {
            title: '后台管理首页',
        },
        redirect: '/manage/Workbench',
        children: [{
                path: 'Workbench',
                name: 'Workbench',
                meta: {
                    title: '工作台',
                    requireAuth: true,
                    showTab: true,
                    keepAlive: true
                },
                component: Workbench
            },
            {
                path: 'Home',
                name: 'Home',
                meta: {
                    title: '管理台',
                    requireAuth: true,
                    showTab: true,
                    keepAlive: true
                },
                component: () =>
                    import ( /* webpackChunkName: "about" */ '@/views/Home.vue')
            },
            {
                path: 'PeopleList',
                name: 'PeopleList',
                meta: {
                    title: '业务员查看',
                    requireAuth: true,
                    showTab: true,
                    keepAlive: true
                },

                component: () =>
                    import ( /* webpackChunkName: "about" */ '@/views/EnterprisePeople/PeopleList.vue')
            },
            {
                path: 'StudentList',
                name: 'StudentList',
                meta: {
                    title: '学员查看',
                    requireAuth: true,
                    showTab: true,
                    keepAlive: true
                },
                component: () =>
                    import ( /* webpackChunkName: "about" */ '@/views/EnterpriseStudent/StudentList.vue')
            },
            {
                path: 'Createaccount',
                name: 'Createaccount',
                meta: {
                    title: '创建用户',
                    showTab: false,
                    requireAuth: true,
                },
                component: () =>
                    import ( /* webpackChunkName: "about" */ '@/views/Accountcreation/Createaccount.vue')
            },
            {
                path: 'CreateEstablish',
                name: 'CreateEstablish',
                meta: {
                    title: '创建企业',
                    requireAuth: true,
                    showTab: false
                },

                component: () =>
                    import ( /* webpackChunkName: "about" */ '@/views/Accountcreation/CreateEstablish.vue')
            },
            {
                path: 'Createstudent',
                name: 'Createstudent',
                meta: {
                    title: '学员注册',
                    requireAuth: true,
                    showTab: false
                },
                
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () =>
                    import ( /* webpackChunkName: "about" */ '@/views/Accountcreation/Createstudent.vue')
            },
            {
                path: 'Studentpuls',
                name: 'Studentpuls',
                meta: {
                    title: '学员注册',
                    requireAuth: true,
                    showTab: false
                },
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () =>
                    import ( /* webpackChunkName: "about" */ '@/views/Registration/Studentpuls.vue')
            },
            {
                path: 'Enterprisesearch',
                name: 'Enterprisesearch',
                meta: {
                    title: '选择企业',
                    requireAuth: true,
                    showTab: false
                },

                component: () =>
                    import ('@/views/Enterprisesearch.vue')
            },
            {
                path: 'Materiallibrary',
                name: 'Materiallibrary',
                meta: {
                    title: '素材库',
                    requireAuth: true,
                    showTab: false
                },

                component: () =>
                    import ('@/views/Student/Materiallibrary/Materiallibrary.vue')
            },
            {
                path: 'Ambassador',
                name: 'Ambassador',
                meta: {
                    title: '提示',
                },
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () =>
                    import ( /* webpackChunkName: "about" */ '../views/Ambassador/Ambassador.vue')
            },
            {
                path: 'jingyaoshi001',
                name: 'jingyaoshi001',
                meta: {
                    title: 'uuid',
                },
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () =>
                    import ( /* webpackChunkName: "about" */ '../views/jingyaoshi001.vue')
            },
            {
                path: 'jingyaoshi002',
                name: 'jingyaoshi002',
                meta: {
                    title: '002',
                },
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () =>
                    import ( /* webpackChunkName: "about" */ '../views/jingyaoshi002.vue')
            },
            {
                path: 'ceshi2',
                name: 'ceshi2',
                meta: {
                    title: '测试',
                },
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () =>
                    import ( /* webpackChunkName: "about" */ '../views/ceshi2.vue')
            },
        ],
        component: () =>
            import ( /* webpackChunkName: "about" */ '@/views/Manage/Manage.vue')
    },
    {
        path: '/reception',
        name: 'reception',
        meta: {
            title: '首页',

        },
        redirect: '/reception/homepage',
        children: [{
                path: 'homepage',
                name: 'homepage',
                meta: {
                    title: '首页',
                    requireAuth: true,
                    showTab: true
                },
                component: () =>
                    import ('@/views/Student/homepage/homepage.vue')
            },
            {
                path: 'mypage',
                name: 'mypage',
                meta: {
                    title: '个人中心',
                    requireAuth: true,
                    showTab: true
                },

                component: () =>
                    import ('@/views/Student/mypage/mypage.vue')
            },
            {
                path: 'BXdetailspage',
                name: 'BXdetailspage',
                meta: {
                    title: '班型详细页',
                    requireAuth: true,
                    showTab: false
                },
                component: () =>
                    import ('@/views/Student/detailspage/BXdetailspage.vue')
            },
            {
                path: 'courseplay',
                name: 'courseplay',
                meta: {
                    title: '课程播放',
                    requireAuth: true,
                    showTab: false
                },
                component: () =>
                    import ('@/views/Student/courseplay/courseplay.vue')
            },
            {
                path: 'purchasepage',
                name: 'purchasepage',
                meta: {
                    title: '购买页',
                    requireAuth: true,
                    showTab: false
                },
                component: () =>
                    import ('@/views/Student/purchasepage/purchasepage.vue')
            },
            {
                path: 'myOrder',
                name: 'myOrder',
                meta: {
                    title: '我的订单',
                    requireAuth: true,
                    showTab: true
                },

                component: () =>
                    import ('@/views/Student/myorder/MyOrderList.vue')
            },
            {
                path: 'Messagereminder',
                name: 'Messagereminder',
                meta: {
                    title: '消息通知',
                    requireAuth: true,
                    showTab: true
                },

                component: () =>
                    import ('@/views/Student/Messagereminder/Messagereminder.vue')
            },
            {
                path: 'index.html',
                name: 'index.html',
                meta: {
                    title: '消息通知',
                    requireAuth: true,
                    showTab: true
                },

                component: () =>
                    import ('@/views/Courseplayback/index.vue')
            },

        ],
        component: () =>
            import ( /* webpackChunkName: "about" */ '@/views/Manage/Reception.vue')
    },
  

    {
        path: '/login',
        name: 'login',
        meta: {
            title: '登录中',

        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/Login/Login.vue')
    },




]

const router = createRouter({
    mode: 'history',
    base: 'EntWeb',
    history: createWebHistory(process.env.BASE_URL),
    routes: routes,

})

export default router