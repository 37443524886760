import ajax from '@/api/index'

const defaultState = {
    studatalist: [],
    datacount: 0,
};

const actions = {
    ///pagedata 查询参数对象
    ///queryunit 查询条件
    GetStudents: (context, pagedata) => {
    

        ajax
            .postAjax("api/EnterpriseStudent/GetEnterpriseStuListByPeoIdAndTime", pagedata)
            .then((res) => {
                if (res.data.meserror == "ok") {
                    if (res.data.mesdata.data) {
                        context.commit('student_update', res.data.mesdata.data);
                        context.commit('datacount_update', res.data.mesdata.datacount);
                    } else {

                        context.commit('student_update', []);
                        context.commit('datacount_update', 0);

                    }


                }
            });
    },
    updatedatalist: (context, student) => {

        context.commit('datalist_update', student);
    }


};

const mutations = {
    student_update: (state, datalist) => {
        state.datalist = datalist;
    },
    datacount_update: (state, count) => {
        state.datacount = count;
    },
    datalist_update: (state, student_) => {
        let index = state.datalist.findIndex((x) => {
            return x._id == student_._id
        });
        state.datalist[index] = student_;
    }
};

const getters = {
    studentdatalist: state => state.datalist,
    studentdatacount: state => state.datacount,
};

export default {
    state: defaultState,
    getters,
    actions,
    mutations,
};