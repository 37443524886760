import ajax from '@/api/index'

const defaultState = {
    datalist: [],
    datacount: 0,
};

const actions = {
    getPeoples: (context, pagedata) => {
        ajax
            .postAjax("api/EnterprisePeople/GetEnterprisePeoPleListByEnterpriseIdAndTime", pagedata)
            .then((res) => {
                if (res.data.meserror == "ok") {
                    if (res.data.mesdata.data) {
                        context.commit('peoples_update', res.data.mesdata.data);
                        context.commit('datacount_update', res.data.mesdata.datacount);

                        //   _this.datalist = res.data.mesdata.data;
                        //   _this.datacount = res.data.mesdata.datacount;
                    } else {
                        //   _this.datalist = [];
                        context.commit('peoples_update', []);
                        context.commit('datacount_update', 0);
                        //   _this.datacount = 0;
                    }


                }
            });
    },
    updatedatalist: (context, peopel) => {

        context.commit('datalist_update', peopel);
    }


};

const mutations = {
    peoples_update: (state, datalist) => {
        state.datalist = datalist;
    },
    datacount_update: (state, count) => {
        state.datacount = count;
    },
    datalist_update: (state, people) => {
        let index = state.datalist.findIndex((x) => {
            return x._id == people._id
        });
        state.datalist[index] = people;
    }
};

const getters = {
    datalist: state => state.datalist,
    datacount: state => state.datacount,
};

export default {
    state: defaultState,
    getters,
    actions,
    mutations,
};