<template>
  <div class="Banners">
    <div class="swiper" style="width: 100%; height: 4rem; position: relative;">
      <!-- <div style="width: 100%; height: 3rem;  position: absolute;top: 0; left: 0; " v-show="imginmg"><img src="" alt=""  style="width: 100%; height: 100%; display: block;" ></div> -->
      <div class="containers" style="overflow: hidden;">
          <ul  ref="imgBox" style="width:2000%; font-size: 0px; height: 4rem;"  @touchstart="TouchStart" @touchmove="TouchMove" @touchend="TouchEnd">
              <li  v-for="(item,index) in imgArr" :key="index" @click ="AdUrlGoFun(item)" >
                  <img :src="item.SrcUrl" alt="">
              </li>
          </ul>
      </div>
      <div class="circle">
          <ol>
              <li v-for="(item, index) in slideCount" :key="index" :class="{current: index === circleIndex}" > </li>
          </ol>
      </div>
    </div>
  </div>
</template>
<script>


import { mapGetters } from "vuex";

export default {

  props: ["action"],

  data: function () {
    return {
      bannerlist: [],
      imgArr: '',
      slideCount:1,//原始数据长度
      totalWidth:0, //图片宽度
      circleIndex:0, //原点下标
      timerId:'', //计时器
      currentIndex:1, //图片下标
      scrolling:false, //是否正在滑动
      startX:0, //手指刚触摸坐标
      currentX:0, // 手指滑动坐标
      distance:0, //差值
      interval: 3000 , // 滚动间隔时间    
      animDuration:500,  //滑动时间
      EnterpriseBanss:{
          id:'',
          page:1,
          pagesize:50,
          datasort:'',
          search:'',
      },
  
    };

  },



  methods: {
    compare(property) {//排序 正序
      return function (a, b) {
        var value1 = a[property];
        var value2 = b[property];
        return value1 - value2;
      }
    },
    getBanner() {
      let _this = this;
      _this.$ajax.getAjax("api/ShopAdv/GetShopAdvListByTypeId", {
          id: "974888f2-8a2f-419a-8788-aa2fd50c0d81",
          datasort: "",
        })
        .then((res) => {
          if (res.data.mesdata.data !== null) {
            //  _this.bannerlist = res.data.mesdata.data;
            _this.imgArr = res.data.mesdata.data.sort(_this.compare('Sort'));
            _this.slideCount = _this.imgArr.length ;
            // setTimeout(()=>{
              _this.handelDom();
            // },200);
            
            
          } else {

          }
        });
    },
    EnterpriseBanst(){
      let _this =  this
      _this.$ajax.getAjax("api/EnterpriseBans/EnterpriseBanss", _this.EnterpriseBanss )
      .then( res=> {
          if(res.data.mesdata.data){
            res.data.mesdata.data.forEach((element,i)=> {
              if(element.BannerOpen == true){
                let newData = JSON.parse(JSON.stringify(res.data.mesdata.data[i]).replace(/Imgsrc/g,"SrcUrl"))
                // let imgArrs = res.data.mesdata.data[i]
                _this.imgArr.push(newData)
              }
                
            });
          }
      })            
    },
    handelDom(){
      let _this = this ;
      const obj1 = JSON.parse(JSON.stringify(_this.imgArr[0])) ;
      const obj2 = JSON.parse(JSON.stringify(_this.imgArr[_this.slideCount-1])) ;
      _this.imgArr .push(obj1);
      _this.imgArr .splice(0,0,obj2)
      // console.log(obj1 , obj2)
      _this.totalWidth = window.innerWidth;
      // _this.totalWidth = _this.$refs.imgBox.offsetWidth
      _this.setTransform(-_this.totalWidth)
    },
    setTransform(pos){
      let _this = this ;
      _this.$refs.imgBox.style.transform = `translate3d(${pos}px,0,0)`;
      _this.seartTimer();
    },
    seartTimer(){
      let _this = this ;
      clearInterval(_this.timerId);
      _this.timerId = setInterval(()=>{
        _this.currentIndex ++ 
        _this.circleIndex ++
        _this.scrollContent(-_this.currentIndex * _this.totalWidth)
      },_this.interval)
    },
    scrollContent(currpos){
      let _this = this ;
      _this.scrolling = true ;
      _this.$refs.imgBox.style.transition =  _this.animDuration/1000 +"s"
      _this.setTransform(currpos)
      _this.checkPosition();
      // _this.Dotmotion();
      _this.scrolling = false ;
    },
    checkPosition(){
      let _this = this ;
      setTimeout(()=>{
        
        if(_this.currentIndex >= _this.slideCount + 1){
          _this.currentIndex = 1 ;
          //  _this.circleIndex = 0;
          _this.$refs.imgBox.style.transition =  "0s"
          _this.setTransform(-_this.totalWidth) ;
          
        }else if(_this.currentIndex <= 0 ){
          _this.currentIndex = _this.slideCount ;
          // _this.circleIndex = _this.slideCount - 1 ;
          _this.$refs.imgBox.style.transition =  "0s"
          _this.setTransform(-_this.totalWidth * _this.currentIndex);
        }
      },_this.animDuration)
       _this.Dotmotion();
    },
    Dotmotion(){
      let _this = this ;
       if(_this.currentIndex >= _this.slideCount + 1 ){
         _this.circleIndex = 0;
       }else if(_this.currentIndex <= 0){
         _this.circleIndex = _this.slideCount - 1 ;
       }
    },
    animateEnd(){
      let _this = this ;
      console.log(1)
    },
    TouchStart(event){// 手指开始触摸事件
      let _this = this ;
      _this.stopTimer() ;
      _this.startX = event.touches[0].pageX ;
      _this.distance = 0 ;
      _this.seartTimer();
      _this.$emit('classFun',true)
    },
    TouchMove(event){// 手指开始移动
      let _this = this ;
      _this.currentX = event.touches[0].pageX ;
      _this.distance = _this.currentX - _this.startX ;
      let move = -_this.currentIndex * _this.totalWidth ;
      let onlindeMove = move + _this.distance ;
      
      _this.setTransform( onlindeMove);
    },
    TouchEnd(event){ // 结束触摸
      let _this = this ;
      _this.$emit('classFun',false)
      let submove = Math.abs(_this.distance);
      
      if(_this.distance === 0){
        return
      }else if(_this.distance > 0 && submove > _this.totalWidth * 0.30){
        _this.currentIndex -- 
        _this.circleIndex --
      }else if(_this.distance < 0 && submove > _this.totalWidth * 0.30){
         _this.currentIndex ++
         _this.circleIndex ++
      };
      _this.scrollContent(-_this.currentIndex * _this.totalWidth) ;
      _this.seartTimer();
      
    },
    AdUrlGoFun(item){
      let _this = this ;

     
    },
    stopTimer(){
      let _this = this ;
      clearInterval(_this.timerId);
    }
  },

  computed:{
      ...mapGetters({
        wxinfo: "wxinfo",
        token: "token",
        LoginUserInfo: "LoginUserInfo",
        urlenterpriseid: "urlenterpriseid",
        urlpeopleid: "urlpeopleid",
        StudentInfo: "StudentInfo",
      }),

  },
  created() {
    let _this = this;
    switch (_this.action) {
      case "home":
        _this.getBanner();
        break;

      default:
        break;
    }
    if(_this.StudentInfo){
        // _this.LoginUserInfos = JSON.parse(_this.LoginUserInfo)
        _this.EnterpriseBanss.id = JSON.parse(_this.StudentInfo).EnterpriseId
    }    
  },
   mounted() {
    let _this = this;
     
  },
  beforeUnmount() { 
      if(this.timerId) { //如果定时器还在运行 或者直接关闭，不用判断
          clearInterval(this.timerId); //关闭
      }
  }
};
</script>

<style lang="scss" scoped>
.Banners {
  .banner {
    width: 100%;
    position: relative;
    height: 0;
    overflow: hidden;
    padding-top: 80%;
    img {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
    }
  }
  .class-nav {
    .class-nav-kemu {
      padding: 0.1rem 0.3rem;
      background: #ccc;
      text-align: center;

      display: inline-block;
      margin: 0 0.1rem;
      color: #fff;
      font-size: 0.28rem;
      border-radius: 0.1rem;
    }
    .active {
      background: chocolate;
    }
  }
  .nut-swiper {
    height: 3rem;
  }
.swiper-container {
    height: 150px;
}  
  .containers ul li {
      width: 5%;
      height: 100%;
      /* display: inline-block; */
      float: left;
  }
  .containers ul li img{
      width: 100%;
      height: 100%;
  }
  .swiper .circle{
      width: 100%;
      height: 20px;
      position: absolute;
      bottom: 0;
      
  }
  .swiper .circle ol{
      display: inline-block;
      height: 20px;
      position: absolute;
      left: 50%;
      transform: translate(-50%);
  
  }
  .swiper .circle ol li{
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: #fff;
      display: inline-block;
      margin: 5px 4px;
  }
  .swiper .circle ol .current {
      background: #ff6700;
  }
}
</style>