<template>
  <div class="urlpopucomcopy">
    <EnterprisePoster @EnterprisePoster="EnterprisePosterMsg" ref="switchings"></EnterprisePoster>
    <div v-if="popupdata">
      <nut-popup
        :position="popupdata.position"
        v-model:visible="popupdata.show"
        @click-overlay="Overlay()"
      >
        <!-- :style="{ height: '40%' }"  -->
        <nut-row>
          <nut-col :span="24">
            <div class="popupdiv">
              <p
                class="flex-content des"
                v-for="(item, index) in popupdata.des"
                :key="index"
              >{{ item }}</p>
              <nut-button type="info btn" @click="copyurl('','Link',$event)">复制链接</nut-button>
              <nut-button
                type="info btn"
                @click="copyurl('','QRcode',$event)"
                :disabled="disabled"
              >生成二维码</nut-button>
            </div>
          </nut-col>
        </nut-row>
      </nut-popup>
      <div class="QRcodeboxs" :style="QRcodebox?'top:0':'top:-120%'">
        <div class="iconboxts">
            <div class="iconboxt" @click="closes()">
              <svg class="icon" aria-hidden="true">
                <use xlink:href="#icon-guanbi" />
              </svg>
            </div>
          </div>
          
        <div class="QRcodebox_nav">
          <div class="QRcodebox_nav_left">
            <div class="QRcodebox_name" style = "height: 1rem; line-height: 1rem; padding:0" @click="EnterprisePosterMsg('-1')" :class="{'active':QRcodeindex=='-1'}">
              默认
            </div>
            <div class="QRcodebox_name_box" v-if="popupdata.key=='student'">
              <div  class="QRcodebox_name" v-for="(item,index) in imgs" :key='index' @click="QRimgs(item , index)" :class="{'active':QRcodeindex==index}">
                {{item.Name}}
              </div>
            </div>
          </div>
          <div class="QRcodebox_nav_right">
            <p class="text">长按保存或转发图片</p>
            <div class="QRcodebox_nav_img_box">
              <div class="QRcodebox_nav_img">
                <img  :src="posterDataUrl">
              </div> 
            </div> 
          </div>



        </div>
        <div class="qrcodebox" >
          <!-- :style="picturesdisplaying?'top:40%':'top:-90%'" -->
          <div class="qrcodenav" ref="barparent" @scroll="scrollEvent">
            <div class="Up" v-if="imgheight">
              <div class="idx_osh_arrow">
                <div class="osha osha_1" style></div>
                <div class="osha osha_2" style></div>
                <div class="osha osha_3" style></div>
              </div>
            </div>
            <div class="imgbox" style>
              <img v-if="posterDataUrl" :src="posterDataUrl" class="poster-bg" ref="imghight" />
              <p class="text">长按保存或转发图片</p>
              <div class="switching" @click="switching">更换连锁海报</div>
            </div>
            <div  style="width: 100%; height: 100%;">
              <div
                v-if="popupdata.key=='people'"
                id="people"
                class="flex-row"
                style="position: relative"
              >
                <img class="poster-bg" src="../../assets/image/erweimaqy1.png" />
                <canvas class="qr" id="qrCode-canvas"></canvas>
              </div>
              <div
                v-if="popupdata.key=='student' || student=='student'"
                id="student"
                class="flex-row"
                style="position: relative"
              >
                <img class="poster-bg" :src="imgUrl" />
                <canvas class="qr" id="qrCode-canvas"></canvas>
              </div>
            </div>
          </div>
          <div class="iconboxts">
            <div class="iconboxt" @click="close()">
              <svg class="icon" aria-hidden="true">
                <use xlink:href="#icon-guanbi" />
              </svg>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="background" v-if="background"></div> -->
    </div>
    <div v-if="popupdatas">
      <!-- <div class="" v-if="popupdatas.display"  @click="preview()">预览</div> -->
      <div class="qrcodenavs">
        <div class="qrcodenav" v-if="previewingimg">
          <img v-if="posterDataUrl" :src="posterDataUrl" class="poster-bg" />
          <div v-else style="width: 100%; height: 100%;">
            <div
              id="preview"
              class="flex-row"
              
            >
            <!-- style="position: relative ; width:7.5rem ;height:100%; margin:0 auto" -->
              <img class="poster-bg" :src="previewingimg" ref="imgsize" />
              <canvas class="qr" id="qrCode-canvas"></canvas>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Toast } from "@nutui/nutui";
import QRCode from "qrcode";
import html2canvas from "html2canvas";
import copyText from "../clipboard";
import { mapGetters } from "vuex";
import { hydrate } from "vue";
import Cardrotation from "../Customerservice/Cardrotation.vue";
import EnterprisePoster from "@/components/Customerservice/EnterprisePoster.vue";
export default {
  //   components: {
  //     Layout,
  //     Row,
  //     Col,
  //   },
  components: { Cardrotation, EnterprisePoster },

  props: ["popupdata", "popupdatas"],

  data: function() {
    return {
      posterDataUrl: "",
      picturesdisplaying: false,
      disabled: false,
      posterlist: "",
      // imgUrl:"/url/assets/5db44935-ea3e-4727-8aaa-c5086eecf333.jpg",
      imgheight: false,
      imgUrl: require("../../assets/image/erweimaxy.png"),
      student: "",
      background: false,
      QRcodeing: "",
      switChingl: "",
      ImgQRCodea:'',
      QRcodebox:false,
      EnterprisePosters:{
          id:'',
          page:'1',
          pagesize:'20',
          datasort:'',
          search:'',
      },
      imgs:'',
      previewingimg:'',//预览图片
      QRcodeindex:'-1'
    };
  },
  computed: {
    ...mapGetters({
      LoginUserInfo: "LoginUserInfo",
      Companylink: "Companylink"
    })
  },

  methods: {
    EnterprisePosterMsg(index) {
      //自定义海报选择
      let _this = this;
      // _this.posterDataUrl = "";
      _this.picturesdisplaying = false;
      Toast.loading("二维码生成中...", {
        cover: true,
        duration: 0
      });
      _this.student = "student";
      _this.QRcodeindex = index
      // if (event.Imgsrc) {
        // console.log(2);
        // // console.log(event.Imgsrc);
        
        // let image = new Image();
        // console.log(image);

        // image.src = event.Imgsrc;
        // image.setAttribute('crossOrigin', 'anonymous');
        // .replace("https://crmapi.jingyaoshi.net/assets/","http://gyx.jingyaoshi.net/")
        // image.crossOrigin = "anonymous";
        // image.crossOrigin = 'Anonymous';
        // console.log(3);
        // _this.getBase64Image(image,function(rs){
        //     _this.imgUrl = rs;
            // setTimeout(function() {
            //   _this.copyurl("posters", "QRcode", event);
            // }, 200);
        // } );

       
        
        // return;
        //  console.log(base64)
        // _this.imgUrl = event.Imgsrc;
        // setTimeout(function() {
        //   _this.copyurl("poster", "QRcode", event);
        // }, 200);
        // _this.ImgQRCode(event)
      // } else {
        _this.imgUrl = require("../../assets/image/erweimaxy.png");
        let item={
            qrcodeMax:'2.820',
            qrcodeTop:'4.000',
            qrcodeLeft:'2.400',
            // Imgsrc:'',
        }
        setTimeout(function() {
          _this.copyurl("poster", "QRcode", item);
        }, 200);
      // }


    },

    ImgQRCode(link,event,type,index){
      let _this = this ;
      let backimgurl ;
      let QRCodeScale ;
      if(type=='yulan'){
        backimgurl = event.dialogImageUrl
        QRCodeScale = event.qrcodeMax
      }else{
        backimgurl = event.Imgsrc
        QRCodeScale = event.qrcodeMax
      }
      let qrcodeurls
      if(link.indexOf('company') !=='-1'){
        qrcodeurls = link + '&companys=63a4c828-337b-4837-a1bf-v65302h00499'
      }else{
        qrcodeurls = link 
      }
      let ImgQRCodelist = {
        backimgurl:backimgurl ,
        qrcodeurl:qrcodeurls,
        left:event.qrcodeLeft,
        top:event.qrcodeTop,
        QRCodeScale:QRCodeScale,
      }
      _this.$ajax.postAjax("api/ShopFile/ImgQRCode",ImgQRCodelist)
      .then(res=>{
       
          if(res.data.meserror == 'ok'){
            _this.posterDataUrl = 'https://' + res.data.mesdata
            Toast.hide();
            _this.disabled = false;
          }else{
            _this.QRimgs(event,index)
          }
      });

      // backimgurl:背景图url,qrcodeurl:二维码url,left:左右位置 int,top:上下位置int,QRCodeScale:二维码大小




    },

    // ImgQRCodes(event){
    //   let _this = this ;
    //   console.log(event)
    //   let ImgQRCodelist = {
    //     backimgurl : event.dialogImageUrl,
    //     qrcodeurl : "https://ent.jingyaoshi.net/manage/Createstudent?people=30ed4f4d-2c60-4f5a-8076-b51ab45b3904&company=63a4c828-337b-4837-a1bf-v65302h00499",
    //     left: event.qrcodeLeft,
    //     top:  event.qrcodeTop,
    //     QRCodeScale : event.qrcodeMax,
    //   }
    //   console.log(ImgQRCodelist)
    //   _this.$ajax.postAjax("api/ShopFile/ImgQRCode",ImgQRCodelist)
    //   .then(res=>{
    //     console.log(res)
    //       if(res.data.meserror == 'ok'){
    //         _this.previewingimg = 'https://'+ res.data.mesdata
    //       }
    //   });  
    // },    



    copyurl(page, type, event,index) {
      //链接或二维码生成
      let _this = this;
      
      let link = _this.popupdata.link;
      switch (_this.popupdata.key) {
        case "people":
          link += _this.HelperCommon.getJsonKey(
            _this.LoginUserInfo,
            "EnterpriseId"
          );
          break;
        case "student":
          if (_this.QRcodeing == "2") {
            link +=
              _this.HelperCommon.getJsonKey(_this.LoginUserInfo, "_id") +
              "&company=" +
              _this.HelperCommon.Company(_this.Companylink, "_id");
          } else {
            link += _this.HelperCommon.getJsonKey(_this.LoginUserInfo, "_id");
          }
          break;
      }
      if (type == "Link") {
        copyText(link, event);
        setTimeout(function() {
          _this.popupdata.show = !_this.popupdata.show;
        }, 300);
      } else {
         _this.QRcodebox = true
        _this.disabled = !_this.disabled;
        if (page == "posters") {
          _this.ImgQRCode(link,event,'shengcheng',index )
          return
        } else {
          Toast.loading("二维码生成中...", {
            cover: true,
            duration: 0
          });
        }
        setTimeout(function() {
          if (_this.popupdata.key == "student") {
            if (page == "poster") {
              let canvasstyle = {
                qrcodeMax: event.qrcodeMax,
                qrcodeTop: event.qrcodeTop,
                qrcodeLeft: event.qrcodeLeft
              };
              _this.createQRCode(_this.popupdata.key, link, canvasstyle);
            } else {
              let canvasstyle = {
                qrcodeMax: "2.820",
                qrcodeTop: "4.000",
                qrcodeLeft: "2.400"
              };
              _this.createQRCode(_this.popupdata.key, link, canvasstyle);
            }
          } else if (_this.popupdata.key == "people") {
            let canvasstyle = {
              qrcodeMax: "2.700",
              qrcodeTop: "8.150",
              qrcodeLeft: "3.250"
            };
            _this.createQRCode(_this.popupdata.key, link, canvasstyle);
          }
        }, 100);
      }
    },
    createQRCode(popupdatakey, link, canvasstyle) {
     
      //生成二维码
      let _this = this;
      //先用 QRCode 生成二维码 canvas，然后用 html2canvas 合成整张海报并转成 base64 显示出来
      let canvas = document.getElementById("qrCode-canvas");
      QRCode.toCanvas(canvas, link, error => {
        if (error) {
        } else {
          canvas.style.width = canvasstyle.qrcodeMax + "rem";
          canvas.style.height = canvasstyle.qrcodeMax + "rem";
          canvas.style.top = canvasstyle.qrcodeTop + "rem";
          canvas.style.left = canvasstyle.qrcodeLeft + "rem";
          let poster = document.getElementById(popupdatakey);
          console.log(poster)
          html2canvas(poster).then(canvas => {
            _this.posterDataUrl = canvas.toDataURL("image/png");
            _this.picturesdisplaying = true;
            _this.popupdata.show = false;
            _this.background = !_this.background;
            Toast.hide();
            setTimeout(function() {
              _this.disabled = false;
              _this.imgload();
            }, 100);
            // _this.$store.dispatch("Companylink", '');
            localStorage.removeItem("ispopupdata");
          });
        }
      });
    },

    close() {
      //关闭二维码面板
      let _this = this;
      _this.picturesdisplaying = !_this.picturesdisplaying;
      // _this.posterDataUrl = "";
      _this.background = !_this.background;
      _this.$refs.switchings.Posterbox = false;
      _this.disabled = false;
    },
    closes() {
      //关闭二维码面板
      let _this = this;
      _this.QRcodebox = false
      _this.disabled = false;
    },
    Overlay(event) {
      //关闭生成面板
      let _this = this;
      localStorage.removeItem("ispopupdata");
    },
    previewing() {
      //预览生成  二维码位置调整
      let _this = this;
      Toast.loading("预览生成中...", {
        cover: true,
        duration: 0
      });
       Toast.hide();
      if (_this.popupdatas.qrcodeMax == "") {
        _this.popupdatas.qrcodeMax = "5";
      }
      if (_this.popupdatas.qrcodeTop == "") {
        _this.popupdatas.qrcodeTop = "0";
      }
      if (_this.popupdatas.qrcodeLeft == "") {
        _this.popupdatas.qrcodeLeft = "0";
      }
      // let Maxhetght =Number(_this.popupdatas.qrcodeTop) + Number(_this.popupdatas.qrcodeMax);
      // let Maxwidth = Number(_this.popupdatas.qrcodeLeft) + Number(_this.popupdatas.qrcodeMax);
      // if (Maxhetght > _this.$refs.imgsize.offsetHeight) {
      //   _this.popupdatas.qrcodeTop = Number(_this.$refs.imgsize.offsetHeight) - Number(_this.popupdatas.qrcodeMax);
      // }
      // if (Maxwidth > _this.$refs.imgsize.offsetWidth) {
      //   _this.popupdatas.qrcodeLeft = Number(_this.$refs.imgsize.offsetWidth) - Number(_this.popupdatas.qrcodeMax);
      // }
      if (_this.popupdatas.qrcodeTop < 0) {
        _this.popupdatas.qrcodeTop = 0;
      }
      if (_this.popupdatas.qrcodeLeft < 0) {
        _this.popupdatas.qrcodeLeft = 0;
      }

      // _this.popupdatas.qrcodeMax = Number(_this.popupdatas.qrcodeMax);
      // _this.popupdatas.qrcodeLeft = Number(_this.popupdatas.qrcodeLeft);
      // _this.popupdatas.qrcodeTop = Number(_this.popupdatas.qrcodeTop);
      let links = "https://ent.jingyaoshi.net/manage/Createstudent?people=30ed4f4d-2c60-4f5a-8076-b51ab45b3904&company=63a4c828-337b-4837-a1bf-v65302h00499";
      _this.ImgQRCode(links, _this.popupdatas , 'yulan')
      // let canvas = document.getElementById("qrCode-canvas");
      // setTimeout(function() {
      //   QRCode.toCanvas(
      //     canvas,
      //     "https://ent.jingyaoshi.net/manage/Createstudent?people=30ed4f4d-2c60-4f5a-8076-b51ab45b3904&company=63a4c828-337b-4837-a1bf-v65302h00499",
      //     res => {
      //       canvas.style.width =
      //         _this.HelperCommon.transformation(_this.popupdatas.qrcodeMax) +
      //         "rem";
      //       canvas.style.height =
      //         _this.HelperCommon.transformation(_this.popupdatas.qrcodeMax) +
      //         "rem";
      //       canvas.style.top =
      //         _this.HelperCommon.transformation(_this.popupdatas.qrcodeTop) +
      //         "rem";
      //       canvas.style.left =
      //         _this.HelperCommon.transformation(_this.popupdatas.qrcodeLeft) +
      //         "rem";
      //       Toast.hide();
      //     }
      //   );
      // }, 100);


    },
   
    pxtoprem() {},

    imgload() {
      //向上滑动提示显示或关闭
      let _this = this;
      // alert(_this.$refs.imghight.offsetHeight +'------'+ _this.$refs.barparent.offsetHeight)
      if (
        _this.$refs.imghight.offsetHeight > _this.$refs.barparent.offsetHeight
      ) {
        _this.imgheight = true;
      } else {
        _this.imgheight = false;
      }
    },
    scrollEvent(e) {
      //向上滑动位置
      let _this = this;
      if (
        e.currentTarget.scrollTop + e.currentTarget.clientHeight >=
        e.currentTarget.scrollHeight
      ) {
        //已到到底部
        _this.imgheight = false;
      } else {
        _this.imgheight = true;
      }
    },
    switching() {
      let _this = this;
      _this.$refs.switchings.Posterbox = !_this.$refs.switchings.Posterbox;
    },
    imgsing() {
      let _this = this;
      // if(_this.$refs.switchings.imgs){
      //   return true
      // }
      // return false
    },
    getBase64Image(img,callback) {

      let canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;
      Toast.hide();
      try {
        let ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, img.width, img.height);
        let ext = img.src.substring(img.src.lastIndexOf(".") + 1).toLowerCase();
        let dataURL = canvas.toDataURL("image/" + ext);
        callback(dataURL)
        
      } catch (error) {
      }
 

    },
    GetPoster(){//获取二维码集合数据
        let _this = this
        _this.$ajax.getAjax("api/EnterprisePoster/EnterprisePosters",_this.EnterprisePosters)
        .then(res=>{
            if(res.data.meserror == 'ok'){
                _this.imgs = res.data.mesdata.data
                // localStorage.setItem('imgsUrl', JSON.stringify(_this.imgs) );
            }
        });
    },
    QRimgs(event,index){
      let _this = this
      // Toast.loading("二维码生成中...", {
      //   cover: true,
      //   duration: 0
      // });      
      _this.QRcodeindex = index
      setTimeout(function() {
        _this.copyurl("posters", "QRcode", event,index);
      }, 200);
      // 
    }
  },
  mounted() {
    let _this = this;
    // if(_this.popupdatas){
    //   _this.previewingimg = _this.popupdatas.dialogImageUrl
    // }

  },

  created() {
    let _this = this;
    // if (localStorage.getItem("imgsUrl")) {
    //   _this.switChingl = JSON.parse(localStorage.getItem("imgsUrl"));
    //   // console.log(localStorage.getItem('imgsUrl'))
    // }
    if(_this.LoginUserInfo){
        _this.EnterprisePosters.id  = JSON.parse(_this.LoginUserInfo).EnterpriseId
    }  
    _this.GetPoster()
  },
  watch: {}
};
</script>

<style lang='scss' scoped>
.popupdiv {
  margin: 0.5rem;
  padding-top: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 0.5rem;
  text-align: center;
  .des {
    width: 100%;
    overflow: auto;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    line-height: 0.5rem;
    font-size: 0.3rem;
    text-align: center;
    color: gray;
    word-wrap: break-word;
    word-break: break-all;
    white-space: pre-wrap;
  }
  .btn {
    margin: 0.4rem 0.1rem;
  }
}
.QRcodeboxs{
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  background: #fff;
  z-index: 99;
  .iconboxts {
    position: relative;
    .iconboxt {
      width: 1rem;
      height: 1rem;
      position: absolute;
      bottom: -1rem;
      right: 0;
      z-index: 99;
      .icon {
        width: 100%;
        height: 100%;
      }
    }
  }
  
  .QRcodebox_nav{
    display: flex;
    
    .QRcodebox_nav_left{
      width: 1.5rem;
      height: 100vh;
      border-right: 1px solid rgb(202, 220, 235);
      font-size: .3rem;
      background: #f5f3f3;
      .QRcodebox_name{
        padding: .2rem .1rem;
        text-align: center;
        
      }
      .QRcodebox_name_box{
        height: calc(100vh - 1.5rem);
        overflow: auto;
      }
    }    
    .QRcodebox_nav_right{
      flex: 1 ;
      height: 100vh ;
      overflow: auto;
      position: relative;
      img{
        width: 100%;
        height: auto;
      }
      .QRcodebox_nav_img{
        
      }
      p{
        position: absolute;
        top: .5rem;
        left: 0;
        transform: translateY(-50%);
        padding: .1rem .1rem;
        color: rgb(0, 153, 255);
        font-size:.3rem ;
      }
      .QRcodebox_nav_img_box{
        height: calc(100vh - 1rem);
        margin-top: 1rem;
        overflow: auto;
      }
    }
  }

  
}
.qrcodebox {
  width: 7.5rem;
  min-height: 8rem;
  max-height: 70vh;
  position: fixed;
  top: -100%;
  left: 50%;
  transform: translate(-50%, -55%);
  z-index: 100;

  .qrcodenav {
    // width: 6rem;
    max-height: 70vh;
    border-radius: 0.1rem;
    overflow: auto;
    margin: 0 auto;
    .Up {
      width: 20%;
      height: 1.2rem;
      position: fixed;
      bottom: 0.2rem;
      left: 50%;
      transform: translateX(-50%);
      z-index: 9;
      // animation: Uping 2.5s linear infinite alternate;
    }
    .imgbox {
      width: 6rem;
      margin: 0 auto;
      position: relative;
      border-radius: 0.1rem;
      overflow: auto;
      .text {
        position: fixed;
        bottom: 0;
        right: 0.75rem;
        font-size: 0.26rem;
        padding: 0.1rem 0;
        font-weight: 650;
        color: rgb(0, 153, 255);
        writing-mode: tb-rl;
        //  border: 1px dashed rgb(0, 153, 255);
        background: rgb(236, 236, 236);
        border-radius: 0.1rem 0 0 0;
      }
      .switching {
        position: fixed;
        bottom: 0;
        left: 0.75rem;
        // transform: translateX(-50%);
        padding: 0.1rem 0.1rem;
        background: cornflowerblue;
        color: #fff;
        border-radius: 0.1rem;
      }
    }
  }
  #qrcode {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .iconboxts {
    position: relative;
    .iconboxt {
      width: 1rem;
      height: 1rem;
      position: absolute;
      bottom: -1rem;
      left: 50%;
      transform: translate(-50%, 0);
      .icon {
        width: 100%;
        height: 100%;
      }
    }
  }
}
.active{
  background: #fff;
}
.poster-bg {
  position: relative;
  width: 100%;
  height: 100%;
  // display: block;
}
.qr {
  position: absolute;
  height: 1px;
}
img {
  width: 100%;
  height: 100%;
}
#people,
#student {
  width: 100%;
  height: 100%;
}
// @keyframes Uping {
//   0% {opacity: 1;} ;
//   100% {opacity: 0;} ;
// }

.idx_osh_arrow {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.idx_osh_arrow .osha {
  display: inline-block;
  width: 0.34rem;
  height: 0.34rem;
  border: 0.06rem solid #00a6ff;
  border-top: 0;
  border-right: 0;
  -webkit-transform-origin: center;
  -moz-transform-origin: center;
  -ms-transform-origin: center;
  transform-origin: center;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
}

.idx_osh_arrow .osha_1 {
  opacity: 0.3;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=30)";
  -webkit-transform: scale(0.8) rotate(135deg);
  -moz-transform: scale(0.8) rotate(135deg);
  -ms-transform: scale(0.8) rotate(135deg);
  transform: scale(0.8) rotate(135deg);
  -webkit-animation: _osha_animation_1 1.2s ease infinite;
  -moz-animation: _osha_animation_1 1.2s ease infinite;
  animation: _osha_animation_1 1.5s ease infinite;
}

.idx_osh_arrow .osha_2 {
  opacity: 0.3;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=30)";
  -webkit-transform: scale(0.9) rotate(135deg);
  -moz-transform: scale(0.9) rotate(135deg);
  -ms-transform: scale(0.9) rotate(135deg);
  transform: scale(0.9) rotate(135deg);
  -webkit-animation: _osha_animation_2 1.2s ease infinite;
  -moz-animation: _osha_animation_2 1.2s ease infinite;
  animation: _osha_animation_2 1.5s ease infinite;
}

.idx_osh_arrow .osha_3 {
  opacity: 0.3;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=30)";
  -webkit-transform: rotate(135deg);
  -moz-transform: rotate(135deg);
  -ms-transform: rotate(135deg);
  transform: rotate(135deg);
  -webkit-animation: _osha_animation_3 1.2s ease infinite;
  -moz-animation: _osha_animation_3 1.2s ease infinite;
  animation: _osha_animation_3 1.5s ease infinite;
}
.background {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 99;
}
@-webkit-keyframes _move_hintBg {
  0% {
    background-position: 0 0;
  }

  100% {
    background-position: -120% 0;
  }
}

@-moz-keyframes _move_hintBg {
  0% {
    background-position: 0 0;
  }

  100% {
    background-position: -120% 0;
  }
}

@keyframes _move_hintBg {
  0% {
    background-position: 0 0;
  }

  100% {
    background-position: -120% 0;
  }
}

@-webkit-keyframes _osha_animation_3 {
  0% {
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  }

  30% {
    opacity: 0.3;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=30)";
  }
}

@-moz-keyframes _osha_animation_3 {
  0% {
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  }

  30% {
    opacity: 0.3;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=30)";
  }
}

@keyframes _osha_animation_3 {
  0% {
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  }

  30% {
    opacity: 0.3;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=30)";
  }
}

@-webkit-keyframes _osha_animation_2 {
  0% {
    opacity: 0.3;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=30)";
  }

  15% {
    opacity: 0.3;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=30)";
  }

  25% {
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  }

  55% {
    opacity: 0.3;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=30)";
  }
}

@-moz-keyframes _osha_animation_2 {
  0% {
    opacity: 0.3;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=30)";
  }

  15% {
    opacity: 0.3;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=30)";
  }

  25% {
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  }

  55% {
    opacity: 0.3;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=30)";
  }
}

@keyframes _osha_animation_2 {
  0% {
    opacity: 0.3;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=30)";
  }

  15% {
    opacity: 0.3;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=30)";
  }

  25% {
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  }

  55% {
    opacity: 0.3;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=30)";
  }
}

@-webkit-keyframes _osha_animation_1 {
  0% {
    opacity: 0.3;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=30)";
  }

  35% {
    opacity: 0.3;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=30)";
  }

  45% {
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  }

  75% {
    opacity: 0.3;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=30)";
  }
}

@-moz-keyframes _osha_animation_1 {
  0% {
    opacity: 0.3;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=30)";
  }

  35% {
    opacity: 0.3;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=30)";
  }

  45% {
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  }

  75% {
    opacity: 0.3;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=30)";
  }
}

@keyframes _osha_animation_1 {
  0% {
    opacity: 0.3;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=30)";
  }

  35% {
    opacity: 0.3;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=30)";
  }

  45% {
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  }

  75% {
    opacity: 0.3;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=30)";
  }
}
</style>