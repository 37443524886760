import Vue from 'vue'
import Clipboard from 'clipboard'
import { Toast } from '@nutui/nutui';
// 文本复制
function copySuccess() {
    Toast.success('复制成功');
}

function copyFail() {
    Toast.fail('不支持复制');
}
export default function copyText(text, e) {
    const clipboard = new Clipboard(e.target, {
        text: () => text
    })
    clipboard.on('success', () => {
        copySuccess()
            // 释放内存
        clipboard.destroy()
    })
    clipboard.on('error', () => {
            // 不支持复制
            copyFail()
                // 释放内存
            clipboard.destroy()
        })
        // 解决第一次点击不生效的问题，如果没有，第一次点击会不生效
    clipboard.onClick(e)
}