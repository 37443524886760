import { createStore } from 'vuex'
import peopleModule from './modules/people';
import studentModule from './modules/student';
import loginModule from './modules/login';
import loadingModule from './modules/loading';
import phoneverification from './modules/phoneverification';

export default createStore({
    modules: {
        people: peopleModule,
        student: studentModule,
        login: loginModule,
        loading: loadingModule,
        Phone:phoneverification,
    },
})