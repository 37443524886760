import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import axios from 'axios';
import goBack from '../public/js/Globalmethod'; //返回方法
import Elementplus from 'element-plus';
import 'element-plus/theme-chalk/index.css';
import XtxUI from '@/components/library/index';
import NutUI from "@nutui/nutui";
import "@nutui/nutui/dist/style.css";
import ajax from '@/api/index'
import HelperCommon from '@/components/HelperCommon'
import './assets/icon/iconfont.css'
import isDialog from '@/util/isDialog/isDialog'
import '@/util/isDialog/isDialog.css'
import loading from '@/util/loading/loading';
import '@/util/loading/loading.css';
import options from '@/assets/js/options'
import address from '@/assets/js/address'
import address_data from '@/assets/js/address_data'
// import Assembly from '@/assets/js/Assembly'
// import MyPlugin from './plugins'
const app = createApp(App);
app.config.globalProperties.$back = goBack;
app.config.globalProperties.$http = axios;
app.config.globalProperties.$ajax = ajax;
app.config.globalProperties.HelperCommon = HelperCommon;
app.config.globalProperties.isDialog = isDialog;
app.config.globalProperties.loading = loading;
app.config.globalProperties.options = options;
app.config.globalProperties.address = address;
app.config.globalProperties.address_data = address_data;
app.use(NutUI);
app.use(Elementplus);
app.use(XtxUI);
app.use(store);
app.use(router);
// app.use(Assembly);
// app.use(MyPlugin);
app.mount('#app');

router.beforeEach((to, from, next) => {
    if (to.meta.title) {
        document.title = to.meta.title
    }
    if(to.path !=='/login'){
        store.dispatch("Address" , to.path)
    }
    //登录的如果是后台
    const type = to.meta.type;
    // if(localStorage.getItem("Biaoshi") == ""){
    //     localStorage.clear();
    // }else{
    //     localStorage.setItem("Biaoshi",'1');
        
    // }
    
    let ua= window.navigator.userAgent.toLowerCase(); //判断用户来源  // 微信||企微||或者浏览器
    if( (ua.match(/MicroMessenger/i) == 'micromessenger') && (ua.match(/wxwork/i) == 'wxwork') ){//企微进入
        localStorage.setItem("wxwork","wxwork");
        if (to.query["enterprise"] || to.query["people"] || to.query["company"] || to.query["Industry"]) {
            let toquery = {
                enterprise:to.query["enterprise"],
                people:to.query["people"],
                company:to.query["company"], 
                Industry:to.query["Industry"]
            }
            localStorage.setItem("toquery",JSON.stringify(toquery));
        }
    }else if( ua.match(/micromessenger/i) == 'micromessenger' ){//微信进入
        localStorage.setItem("wxwork","");
        localStorage.setItem("toquery","");
        if (to.query["enterprise"] || to.query["people"] || to.query["company"] || to.query["Industry"]) {
           
            store.dispatch("seturlid", { urlenterpriseid: to.query["enterprise"], urlpeopleid: to.query["people"], urlcompanyid: to.query["company"], urlIndustryid: to.query["Industry"] })
        }
    }else{//浏览器进入
  
    }
    let routerpath
    if(to.query.wxwork == 'wxwork'){//企微
        store.dispatch("Address" , '')
        localStorage.setItem("Topath",to.query.topath);
        let toquery = JSON.parse(to.query.toquery);
        let enterprise = toquery.enterprise ;
        let people = toquery.people ;
        let company = toquery.company ;
        let Industry = toquery.Industry ;
        if (enterprise || people || company || Industry) {
            store.dispatch("seturlid", { urlenterpriseid: enterprise, urlpeopleid: people, urlcompanyid: company ,urlIndustryid:Industry})
        }
        routerpath = to.query.topath
    }else{
        routerpath = to.path
    }
    if(routerpath == "/manage/Ambassador" ){//公益大使进入
        if (store.getters.wxinfo) {
            if(store.getters.LoginUserInfo){
                next('/manage')
            }else{
                next()
            }
        }else {
            localStorage.setItem("LoginAfterPath", JSON.stringify(to));
            // next('/login')
            window.location.href = ajax.WxloginURL + "?topath=" + store.getters.address + '&wxwork=' + localStorage.getItem("wxwork") +'&toquery=' + localStorage.getItem("toquery") ;

        }
    }
    else if(routerpath == "/manage/Createaccount" ){//管理员 业务员进入
        if (store.getters.wxinfo) {
            if(store.getters.LoginUserInfo){
                next('/manage')
            }else{
                next()
            }
        }else{
            localStorage.setItem("LoginAfterPath", JSON.stringify(to));
            // next('/login')
            window.location.href = ajax.WxloginURL + "?topath=" + store.getters.address + '&wxwork=' + localStorage.getItem("wxwork") +'&toquery=' + localStorage.getItem("toquery") ;

        }
    }
    else if (routerpath == "/manage/Createstudent" ){//创建学员账号
        if (store.getters.wxinfo) { 

            if(store.getters.StudentInfo){
                next('/reception/homepage')
            }else{
                next()
            }
       
                
           
            
        } else {
            localStorage.setItem("LoginAfterPath", JSON.stringify(to));
            // next('/login')
            window.location.href = ajax.WxloginURL + "?topath=" + store.getters.address + '&wxwork=' + localStorage.getItem("wxwork") +'&toquery=' + localStorage.getItem("toquery") ;

        }
    }
    else if (routerpath == "/reception/homepage" ){//学员登录
        if (store.getters.wxinfo) { 
            next()

        }
        else {
            localStorage.setItem("LoginAfterPath", JSON.stringify(to));
            // next('/login')
            window.location.href = ajax.WxloginURL + "?topath=" + store.getters.address + '&wxwork=' + localStorage.getItem("wxwork") +'&toquery=' + localStorage.getItem("toquery") ;
         
        }
    }
    else if (to.meta.requireAuth) {
        if(store.getters.address=='/manage/Workbench' || store.getters.address=='/manage/Createaccount' ){
            if (store.getters.LoginUserInfo ) {
                if(JSON.parse(store.getters.LoginUserInfo).IsAdmin && !JSON.parse(store.getters.LoginUserInfo).EnterpriseId){
                    next('/reception/homepage')
                }else{
                    next()
                }
            } 
            else {
                localStorage.setItem("LoginAfterPath", JSON.stringify(to));
                // next('/login')
                window.location.href = ajax.WxloginURL + "?topath=" + store.getters.address + '&wxwork=' + localStorage.getItem("wxwork") +'&toquery=' + localStorage.getItem("toquery") ;

            }
        }else{
            next()
        }
    }
    else{
        next()
    }

   
   
})

