var HelperCommon = {
    ///将时间戳转换成日期
    getLocalTime(datetime) {
        var a = new Date(datetime);
        var beijingTimeValue = a.getFullYear() + "-" + (a.getMonth() + 1) + "-" + a.getDate() + " ";
        if (a.getHours() < 10) {
            beijingTimeValue += "0" + a.getHours() + ":"
        } else {
            beijingTimeValue += a.getHours() + ":"
        }
        if (a.getMinutes() < 10) {
            beijingTimeValue += "0" + a.getMinutes() + ":"
        } else {
            beijingTimeValue += a.getMinutes() + ":"
        }
        if (a.getSeconds() < 10) {
            beijingTimeValue += "0" + a.getSeconds() + ""
        } else {
            beijingTimeValue += a.getSeconds() + ""
        }

        return beijingTimeValue;

    },
    tellPhone(phone, callback) {
        window.location.href = "tel:" + phone;
        callback;
    },
    routerJump(_this, path) {
        _this.$router.push({
            name: path,
            // params: { id: route }
        });
    },
    getQueryString(name) {
     
        let reg = `(^|&)${name}=([^&]*)(&|$)`;
        let r = window.location.search.substr(1).match(reg);
        if (r != null) return unescape(r[2]);
        return null;
    },

    getJsonKey(str, id) {
        try {
            if (str) {
                let json = JSON.parse(str);
                return json[id];
            }
        } catch (error) {
            return "";
        }
        return "";
    },
    Company(str, id){
        try {
            if (str) {
                let json = JSON.parse(str);
                return json.item[id];
            }
        } catch (error) {
            return "";
        }
        return "";
    },
    ///获取当前日期及当前时间
    getTodayTimes() {
        {
            var now = new Date();
            var year = now.getFullYear(); //年
            var month = now.getMonth() + 1; //月
            var day = now.getDate(); //日
            var hh = now.getHours(); //时
            var mm = now.getMinutes(); //分
            var ss = now.getSeconds();//秒
            var clock = year + "-";
            if (month < 10)
                clock += "0";
            clock += month + "-";
            if (day < 10)
                clock += "0";
            clock += day + " ";
            if (hh < 10)
                clock += "0";
            clock += hh + ":";
            if (mm < 10) clock += '0';
            clock += mm;
            // if (ss < 10) clock += '0';
            // clock += ss;
            return (clock);
        }
    },
    ///获取当前日期及00:00:00
    getToday() {
        {
            var d = new Date(),
                str = '';
            str += d.getFullYear() + '-'; //获取当前年份 
            str += d.getMonth() + 1 + '-'; //获取当前月份（0——11） 
            str += d.getDate() + ' ';
            str += '00:00:00';

            return str;
        }
    },
    //日期转换为时间戳
    DateToTimeStamp(date) {
        var date = new Date(date);
        return date.getTime();
    },
    getlocalStorage(key) {
        let keyobj = window.localStorage.getItem(key);
        if (keyobj) {
            try {
                return JSON.parse(keyobj);
            } catch (error) {
                return keyobj;
            }

        } else { 
            return null;
        }
    },
    setlocalStorage(key, obj) {
        window.localStorage.setItem(key, JSON.stringify(obj));
        return true;

    },

    transformation(number){
        let deviceWidth = document.documentElement.clientWidth || window.innerWidth;
        if(deviceWidth > 750){
            deviceWidth = 525
        }else if(deviceWidth <= 320){
            deviceWidth = 320
        }
        let num =  (number/(deviceWidth / 7.5))
        return num
    },

    formateTimeStamp (timeStamp) {
        var day; 
        var hour;
        var min;
        var seconds;
        day = Math.floor(timeStamp / 1000 / 60 / 60 / 24);
        hour = Math.floor((timeStamp / 1000 / 60 / 60) % 24);
        min = Math.floor((timeStamp / 1000 / 60) % 60);
        seconds = Math.floor((timeStamp / 1000) % 60);

        if (day < 10 && day > 0 ) {
          day = '0' + day;
        }
        
        if (hour < 10) {
          hour = '0' + hour
        };
    
        if (min < 10) {
          min = '0' + min;
        }
    
        if (seconds < 10) {
          seconds = '0' + seconds;
        }
        
        const restStamp = {
          day: day,
          hour: hour,
          min: min,
          seconds: seconds
        }
        return restStamp
    },
      ///获取当前日期及当前时间
      getTodayweekTime() {
        {
            var now = new Date();
            var show_day = new Array("星期日","星期一","星期二","星期三","星期四","星期五","星期六");
            var days = now.getDay();
            var year = now.getFullYear(); //年
            var month = now.getMonth() + 1; //月
            var day = now.getDate(); //日
            var hh = now.getHours(); //时
            var mm = now.getMinutes(); //分
            var ss = now.getSeconds();//秒
            var week = show_day[days]
            var clock = year + "-";
            if (month < 10)
                clock += "0";
            clock += month + "-";
            if (day < 10)
                clock += "0";
            clock += day + " ";
            // if (hh < 10)
            //     clock += "0";
            // clock += hh + ":";
            // if (mm < 10) clock += '0';
            // clock += mm + ":";
            // if (ss < 10) clock += '0';
            // clock += ss + ' ';

            clock += week;
            return (clock);
        }
    },
    //  秒数转化为时分秒
    FormatSeconds(value) {
    //  秒
    let second = parseInt(value)
    //  分
    let minute = 0
    //  小时
    let hour = 0
    //  天
    //  let day = 0
    //  如果秒数大于60，将秒数转换成整数
    if (second > 60) {
      //  获取分钟，除以60取整数，得到整数分钟
      minute = parseInt(second / 60)
      //  获取秒数，秒数取佘，得到整数秒数
      second = parseInt(second % 60)
      //  如果分钟大于60，将分钟转换成小时
      if (minute > 60) {
        //  获取小时，获取分钟除以60，得到整数小时
        hour = parseInt(minute / 60)
        //  获取小时后取佘的分，获取分钟除以60取佘的分
        minute = parseInt(minute % 60)
        //  如果小时大于24，将小时转换成天
        //  if (hour > 23) {
        //    //  获取天数，获取小时除以24，得到整天数
        //    day = parseInt(hour / 24)
        //    //  获取天数后取余的小时，获取小时除以24取余的小时
        //    hour = parseInt(hour % 24)
        //  }
      }
    }
  
    let result = '' + parseInt(second) 
    if (minute > 0) {
      result = '' + parseInt(minute) + ':' + result
    }
    if (hour > 0) {
      result = '' + parseInt(hour) + ':' + result
    }
    //  if (day > 0) {
    //    result = '' + parseInt(day) + '天' + result
    //  }
 
    return result
  }

}
export default HelperCommon;